export default class ScoreBoard
{
	// Set of all completed tasks by all users
	_completedTasks = []
	get completedTasks() {
		return this._completedTasks;
	}

	_xpScoreboard = [];
	get xpScoreboard() {
		return this._xpScoreboard;
	}

	_hydrazineScoreboard = [];
	get hydrazineScoreboard() {
		return this._hydrazineScoreboard;
	}

	constructor()
	{
		this._xpScoreboard = [
			{
				userId: null,
				userName: 'Jesse Wilcher',
				userAvatar: 'https://api.multiavatar.com/e57b60f3-7ba4-4691-80ee-adac483f16c9.svg',
				xp: 341
			},
			{
				userId: null,
				userName: 'London Feron',
				userAvatar: 'https://api.multiavatar.com/cdc09ebb-3230-4d8f-b494-041ea5c87531.svg',
				xp: 320
			},
			{
				userId: null,
				userName: 'Elvira Marsh',
				userAvatar: 'https://api.multiavatar.com/8730b378-7d84-483d-b516-5c24ef484fda.svg',
				xp: 250
			},
			{
				userId: null,
				userName: 'Elmer Cannon',
				userAvatar: 'https://api.multiavatar.com/604ea516-b18d-4f2f-97f7-8cf0db32a4c0.svg',
				xp: 198
			},
			{
				userId: null,
				userName: 'Hannah Grant',
				userAvatar: 'https://api.multiavatar.com/462243fd-42e6-40fb-b4c0-a30f7f3dcb9d.svg',
				xp: 112
			},
			{
				userId: null,
				userName: 'Beatrice Newman',
				userAvatar: 'https://api.multiavatar.com/c9d6b103-3335-4790-b658-08a10675ec5f.svg',
				xp: 89
			},
			{
				userId: null,
				userName: 'Fletcher Vasquez',
				userAvatar: 'https://api.multiavatar.com/874e274d-83fc-446c-8dec-fce5813b68df.svg',
				xp: 62
			},
			{
				userId: null,
				userName: 'Lizzie Malone',
				userAvatar: 'https://api.multiavatar.com/af21a5eb-7ecb-4153-9a7a-270a30548dee.svg',
				xp: 29
			},
			{
				userId: null,
				userName: 'Marshall Norman',
				userAvatar: 'https://api.multiavatar.com/57eb4e36-4c7c-4ff3-a66b-2c410a795c07.svg',
				xp: 15
			},
			{
				userId: null,
				userName: 'Nelson King',
				userAvatar: 'https://api.multiavatar.com/4f572742-3b34-490e-9832-724cb14a6ab3.svg',
				xp: 3
			}
		]

		this._hydrazineScoreboard = [
			{
				userId: null,
				userName: 'Elmer Cannon',
				userAvatar: 'https://api.multiavatar.com/604ea516-b18d-4f2f-97f7-8cf0db32a4c0.svg',
				hydrazine: 164
			},
			{
				userId: null,
				userName: 'Winona Grant',
				userAvatar: 'https://api.multiavatar.com/cb4a8cdc-e953-4358-a086-0feb668d7e48.svg',
				hydrazine: 124
			},
			{
				userId: null,
				userName: 'London Feron',
				userAvatar: 'https://api.multiavatar.com/cdc09ebb-3230-4d8f-b494-041ea5c87531.svg',
				hydrazine: 122
			},
			{
				userId: null,
				userName: 'Waldo Smith',
				userAvatar: 'https://api.multiavatar.com/e335a795-3673-4176-ae55-cb93bfa36a78.svg',
				hydrazine: 107
			},
			{
				userId: null,
				userName: 'Irene Castillo',
				userAvatar: 'https://api.multiavatar.com/83b99917-94a4-4010-8262-01d09e974483.svg',
				hydrazine: 97
			},
			{
				userId: null,
				userName: 'Milton Brooks',
				userAvatar: 'https://api.multiavatar.com/400ed4fb-bf23-4ac0-b5db-e7fc1e09c5c9.svg',
				hydrazine: 95
			},
			{
				userId: null,
				userName: 'Hattie Robbins',
				userAvatar: 'https://api.multiavatar.com/ef215796-38ce-45e5-a085-a209b8950167.svg',
				hydrazine: 53
			},
			{
				userId: null,
				userName: 'Ulva Russell',
				userAvatar: 'https://api.multiavatar.com/edee0a72-94f7-4a18-9b50-3e49e3bb604e.svg',
				hydrazine: 28
			},
			{
				userId: null,
				userName: 'Isaiah Keller',
				userAvatar: 'https://api.multiavatar.com/46089f1b-93f6-4d35-844e-8de52ac5ffbd.svg',
				hydrazine: 14
			},
			{
				userId: null,
				userName: 'Rosalind Ballard',
				userAvatar: 'https://api.multiavatar.com/2a59a3e3-9882-49dc-b17a-2026300e6291.svg',
				hydrazine: 4
			}
		]
	}

	isTaskCompleted(taskId)
	{
		return this._completedTasks.indexOf(taskId) != -1
	}

	update(userLite, xp, hydrazine, tasks)
	{
		this.updateXP(userLite, xp);
		this.updateHydrazine(userLite, hydrazine);
		tasks.forEach(t => this.addCompletedTask(t))
	}

	updateXP(userLite, xp)
	{
		let entry = this._xpScoreboard.find(x => x.userId == userLite.id);
		if(entry)
		{
			// update name in case of changes, userNameChanged is not currently propagated here
			entry.userName = userLite.name
			entry.xp = xp;
			this._xpScoreboard.sort((a, b) => b.xp - a.xp)
			return;
		}

		entry = {
			userId: userLite.id,
			userName: userLite.name,
			userAvatar: userLite.avatar,
			xp: xp
		}

		this._xpScoreboard.push(entry);
		this._xpScoreboard.sort((a, b) => b.xp - a.xp)

		if(this._xpScoreboard.length > 10)
			this._xpScoreboard.splice(10)
	}

	updateHydrazine(userLite, hydrazine)
	{
		let entry = this._hydrazineScoreboard.find(x => x.userId == userLite.id);
		if(entry)
		{
			// update name in case of changes, userNameChanged is not currently propagated here
			entry.userName = userLite.name
			entry.hydrazine = hydrazine;
			this._hydrazineScoreboard.sort((a, b) => b.hydrazine - a.hydrazine)
			return;
		}

		entry = {
			userId: userLite.id,
			userName: userLite.name,
			userAvatar: userLite.avatar,
			hydrazine: hydrazine
		}

		this._hydrazineScoreboard.push(entry);
		this._hydrazineScoreboard.sort((a, b) => b.hydrazine - a.hydrazine)

		if(this._hydrazineScoreboard.length > 10)
			this._hydrazineScoreboard.splice(10)
	}

	addCompletedTask(taskId)
	{
		if(!this.isTaskCompleted(taskId))
		{
			this._completedTasks.push(taskId);
			return true;
		}

		return false;
	}
}