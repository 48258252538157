import {registerClassForPersistence, registerMapping, registerMigration} from '../lib/automapper.js'

import User from './users/user.js'
import UserView from './users/userView.js';
import UserLite from './users/userLite.js';
import PendingCertificationAttempt from './users/pendingCertificationAttempt.js';
import PendingMAPAttempt from './users/pendingMAPAttempt.js';
import MembershipNumber from './users/membershipNumber.js';
import Rocket from './rockets/rocket.js';
import RocketView from './rockets/rocketView.js';
import UserSearch from './users/userSearch.js';
import ScoreBoard from './map/scoreboard.js';
import DateTime from '../lib/dateTime.js';

/// DateTimes
registerMapping((key, value) => {
    if(value instanceof DateTime)
        return value.valueOf()
    return undefined;
}, (key, value) => {
    if((key.substr(key.length - 2, 2) == 'On') && typeof value == 'number')
    {
        let datetime = new DateTime(value);
        return datetime;
    }

    return undefined;
})

registerClassForPersistence(User);
registerClassForPersistence(UserView);
registerClassForPersistence(UserLite);
registerClassForPersistence(UserSearch);

registerClassForPersistence(PendingCertificationAttempt);
registerClassForPersistence(PendingMAPAttempt);

registerClassForPersistence(MembershipNumber);

registerClassForPersistence(Rocket);
registerClassForPersistence(RocketView);

registerClassForPersistence(ScoreBoard);

// TODO: EventReport?

// map2, can be killed because only people with this data should be testers
registerMigration((key, parent, value) => key == "map2" && (parent._type == "User" || parent._type == "UserLite" || parent._type == "UserView"), (key, parent, value) => undefined)
/// old map
registerMigration((key, parent, value) => key == "map" && value.attemptPending === undefined && (parent._type == "User" || parent._type == "UserLite" || parent._type == "UserView"), (key, parent, value) => undefined)


