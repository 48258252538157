export const raw = {
	subjects: {
		'bonus': {
			name: 'Bonus',
			color: 'red',
			tasks: {
				'swing': {
					name: 'Swing Test',
					desc: 'Conduct a swing test',
					hydrazine: 2,
					fullDesc: 'A swing test is where you tie a line to your rocket aroung its CG and spin it around your head to check that it flies stable.',
					rules: [
						'Swing test must be performed competently',
						'Swing tests often take multiple goes to get right, this is acceptable',
						'Rocket must pass the swing test and fly stable'
					]
				},
				'diy-plastic-chute': {
					name: 'DIY parachute',
					desc: 'Build and fly a plastic parachute',
					hydrazine: 2,
					fullDesc: 'Construct your own parachute from plastic sheet, such as a bin liner or carrier bag.',
					rules: [
						'The parachute must have multiple lines securely attached to it',
						'It can be any polygon shape and may include a spill hole'
					]
				},
				'baffle': {
					name: 'Baffle',
					desc: 'Build and fly a rocket with a baffle',
					hydrazine: 5,
					rules: [
						'Baffle may be retrofitted to an existing rocket or built into a new rocket'
					]
				},
				'sim': {
					name: 'Simulation',
					desc: 'Simulate a rocket and flight',
					hydrazine: 5,
					rules: [
						'Use common simulation software such as OpenRocket or RockSim',
						'Must be an accurate simulation of dimensions and masses',
						'Flier must be able to explain the flight profile in reasonable detail showing their understanding of what the simulation is showing'
					]
				},
				'drag-race': {
					name: 'Drag race',
					desc: 'Challenge another rocketeer',
					hydrazine: 10,
					rules: [
						'You do not need to win, just participate with another MAP participant'
					]
				},
				'papering': {
					name: 'Fin papering',
					desc: 'Laminate balsa fins for strength',
					hydrazine: 15,
					rules: [
						'Papering must be well applied to all the fins',
						'Flyer must be able to explain to the MAP examiner what materials they used and what process they followed'
					]
				},
				'record': {
					name: 'Altitude Record',
					desc: 'Attempt an altitude record',
					hydrazine: 25,
					rules: [
						'You do not need to break the record, just make a serious attempt',
						'You must follow the rules of altitude record attmepts: https://ukra.org.uk/info/records/'
					]
				},
				'canard': {
					name: 'Canard fins',
					desc: 'Build a rocket with canard fins',
					hydrazine: 15,
					rules: [
						'At least 2 aerodynamically significant canard fins must be mounted less than 33% rocket length from the nose'
					]
				},
				'aerotech': {
					name: 'RMS - Aerotech',
					desc: 'Assemble and fly an Aerotech RMS motor',
					hydrazine: 20,
					rules: [
						'Must be a reloadable motor, not a single-use "DMS" motor.',
						'You do not need to buy the casing/closures, you may rent or borrow them',
						'You should be supervised by the vendor or other experienced flyer if this is your first time'
					]
				},
				'cti': {
					name: 'RMS - Cesaroni',
					desc: 'Assemble and fly a Cesaroni RMS motor',
					hydrazine: 15,
					rules: [
						'You do not need to buy the casing/closures, you may rent or borrow them',
						'You should be supervised by the vendor or other experienced flyer if this is your first time'
					]
				},
				'3dprinting': {
					name: '3D Printed Components',
					desc: '3D print components for a rocket',
					hydrazine: 15,
					rules: [
						'Components must be non-trivial, small farings or rail guides do not qualify. Nose cones, fin cans, electronics sleds all qualify',
						'Component(s) must be designed and integrated into the rocket by the flier',
						'The item(s) may be printed by a 3rd party'
					]
				},
				'spin-stabilised': {
					name: 'Spin Stabilised',
					desc: 'Build and fly a spin-stabilised rocket',
					hydrazine: 15,
					rules: [
						'Rocket must be designed to spin and not just do so because of construction quirks',
						'This is typically achieved by canting the fins',
						'Whirly-birds and monocopters do not qualify, it must be a "normal" rocket'
					]
				},
				'piston-ejection': {
					name: 'Piston Ejection',
					desc: 'Use a piston to eject your recovery device',
					hydrazine: 15,
					rules: [
						'Can be a new or existing rocket with a piston retro-fitted.'
					]
				},
				'fibreglass': {
					name: 'Fibreglass',
					desc: 'Use fibreglass to reinforce fins or body tubes',
					hydrazine: 10,
					rules: [
						'Preformed fibreglass kits or parts do not qualify, it must be wrapped/applied by the rocketeer (or an assistant under the rocketeers instruction)'
					]
				},
				'carbonfibre': {
					name: 'Carbon Fibre',
					desc: 'Use carbon fibre to reinforce fins or body tubes',
					hydrazine: 10,
					rules: [
						'Preformed carbon fibre kits or parts do not qualify, it must be wrapped/applied by the rocketeer (or an assistant under the rocketeers instruction)'
					]
				}
			}
		},
		'propulsion': { 
			name: 'Propulsion',
			color: 'yellow',
			tasks: {
				'A/4': {
					name: '1/4 A Motor',
					desc: 'Fly a rocket on a 1/4 A motor',
					xp: 1,
					rules: [
						'When clustering or staging it is an individual motor that counts, not total impulse of all motors. At least one 1/4A motor must be used.'
					]
				},
				'A/2': {
					name: '1/2 A Motor',
					desc: 'Fly a rocket on a 1/2 A motor',
					xp: 2,
					rules: [
						'When clustering or staging it is an individual motor that counts, not total impulse of all motors. At least one 1/2A motor must be used.'
					]
				},
				'A': {
					name: 'A Motor',
					desc: 'Fly a rocket on an A motor',
					xp: 3,
					rules: [
						'When clustering or staging it is an individual motor that counts, not total impulse of all motors. At least one A motor must be used.'
					]
				},
				'B': {
					name: 'B Motor',
					desc: 'Fly a rocket on a B motor',
					xp: 5,
					rules: [
						'When clustering or staging it is an individual motor that counts, not total impulse of all motors. At least one B motor must be used.'
					]
				},
				'C': {
					name: 'C Motor',
					desc: 'Fly a rocket on a C motor',
					xp: 5,
					rules: [
						'When clustering or staging it is an individual motor that counts, not total impulse of all motors. At least one C motor must be used.'
					]
				},
				'D': {
					name: 'D Motor',
					desc: 'Fly a rocket on a D motor',
					xp: 5,
					rules: [
						'When clustering or staging it is an individual motor that counts, not total impulse of all motors. At least one D motor must be used.'
					]
				},
				'E': {
					name: 'E Motor',
					desc: 'Fly a rocket on a E motor',
					xp: 10,
					rules: [
						'When clustering or staging it is an individual motor that counts, not total impulse of all motors. At least one E motor must be used.'
					]
				},
				'F': {
					name: 'F Motor',
					desc: 'Fly a rocket on a F motor',
					xp: 15,
					rules: [
						'When clustering or staging it is an individual motor that counts, not total impulse of all motors. At least one F motor must be used.'
					]
				},
				'G': {
					name: 'G Motor',
					desc: 'Fly a rocket on a G motor',
					xp: 35,
					rules: [
						'When clustering or staging it is an individual motor that counts, not total impulse of all motors. At least one G motor must be used.'
					]
				},
				'micro-hybrid': {
					name: 'Micro-hybrid',
					desc: 'Fly a rocket on a micro-hybrid motor',
					xp: 100,
					rules: [
						"Requires Safety and Technical comittee oversight"
					]
				},
				'clustercomp18': {
					name: 'Cluster (18mm)',
					desc: 'Fly a rocket on a cluster of 18mm composite motors',
					xp: 25,
					rules: [
						'All motors must be 18mm or smaller',
						'Cluster and ignition method must be inspected by the FCO prior to launch.',
						'All motors must ignite properly and as intended',
						'"Composite" means any non-black-powder propellant'
					]
				},
				'clustercomp24': {
					name: 'Cluster (24+mm)',
					desc: 'Fly a rocket on a cluster of 24+mm composite motors',
					xp: 30,
					rules: [
						'Cluster and ignition method must be inspected by the FCO prior to launch.',
						'"Composite" means any non-black-powder propellant'
					]
				}
			}
		},
		'construction': {
			name: 'Construction',
			color: 'blue',
			tasks: {
				'rtf': {
					name: 'RTF',
					desc: 'Fly a Ready-To-Fly rocket',
					xp: 2,
					fullDesc: 'Fly a prefabricated (ready-to-fly) rocket',
					rules: [
						'Must be a rocket you havent used before in MAP'
					]
				},
				'assembly': {
					name: 'Assembly-Only',
					desc: 'Build and fly an assembly-only rocket kit',
					xp: 3,
					fullDesc: 'Build and fly a prefabricated (simple assembly required) rocket kit',
					rules: [
						'Must be a rocket you havent used before in MAP'
					]
				},
				'kit': {
					name: 'Kit',
					desc: 'Build and fly a rocket kit',
					xp: 3,
					rules: [
						'Any comercially available kit can be used'
					]
				},
				'cone': {
					name: 'Chip Cone',
					desc: 'Build and fly a chip-cone',
					xp: 3,
					rules: [
						'The cone may be polystyrene or cardboard, either prefabricated or hand-made',
						'The motor may be permanently attached to the cone or in a mounting tube',
						'Polystyrene cones can be hard to come by these days, if using cardboard be conscious of fire hazards. Do not fly over dry crops or grass.'
					]
				},
				'whirly': {
					name: 'Whirly-bird',
					desc: 'Build and fly a spinning rocket',
					xp: 3,
					rules: [
						'This is not to be confused with a spin-stabilised "traditionally shaped" rocket, but is typically a flat, aerofoiled disc which rapidly spins as it moves through the air',
						'May be a kit, prefabricated or 3D printed'
					]
				},
				'pyramid': {
					name: 'Pyramid',
					desc: 'Build and fly a pyramid rocket',
					xp: 5,
					rules: [
						'The rocket may be a kit or home made, but not prefabricated or 3d-printed in a single piece'
					]
				},
				'scratch': {
					name: 'Scratch Build',
					desc: 'Design, build and fly a rocket from scratch',
					xp: 10,
					rules: [
						'Must be designed, built and flown by you',
						'Simulations are encouraged, and are required at the RSO\'s discretion depending on the predictability of the rocket\'s design'
					]
				},
				'2stagebp': {
					name: 'Two Stager (BP)',
					desc: 'Build and fly a 2-stage rocket on BP motors',
					xp: 10,
					rules: [
						'Any hot-gas staging mechanism may be used except CHAD',
						'The booster stage must must have structural components and not consist of just a motor (i.e. CHAD)',
						'The second stage must ignite properly as intended',
						'Both stages must be recovered (examiners discretion may be used for exceptional circumstances - simply not-finding the booster is not permitted)'
					]
				},
				'3stagebp': {
					name: 'Three Stager (BP)',
					desc: 'Build and fly a 3-stage rocket on BP motors',
					xp: 15,
					rules: [
						'Any hot-gas staging mechanism may be used except CHAD',
						'The booster stage must must have structural components and not consist of just a motor (i.e. CHAD)',
						'The second and third stages must ignite properly as intended',
						'All stages must be recovered (examiners discretion may be used for exceptional circumstances - simply not-finding the boosters is not permitted)'
					]
				},
				'clusterbp': {
					name: 'Cluster (BP)',
					desc: 'Build and fly a rocket with a cluster of BP motors',
					xp: 10,
					rules: [
						'All motors must ignite properly'
					]
				},
				'scale': {
					name: 'Scale Scratch',
					desc: 'Design, build and fly a scale model of a real rocket',
					xp: 15,
					rules: [
						'Must be a scale model of a real rocket/missile',
						'Must be accurately scaled (exceptions for static vs dynamic stability differences)'
					]
				},
				'upscale': {
					name: 'Upscale',
					desc: 'Design, build and fly an upscaled rocket',
					xp: 10,
					rules: [
						'Must be scaled, built and flown by you.',
						'Must be an upscale of an existing kit or real rocket/missile'
					]
				},
				'downscale': {
					name: 'Downscale',
					desc: 'Design, build and fly a downscaled rocket',
					xp: 10,
					rules: [
						'Must be scaled, built and flown by you.',
						'Must be a downscale of an existing kit or real rocket/missile'
					]
				},
				'oddroc': {
					name: 'Oddroc',
					desc: 'Design, build and fly an unusual rocket',
					xp: 25,
					rules: [
						'Must be suitably odd, e.g. a UFO, wonky ACME rocket, spool, TARDIS',
						'Do not confuse this with the Non-Standard-Components task'
					]
				},
				'ring-fin': {
					name: 'Ring Fin',
					desc: 'Build and fly a rocket with a ring fin',
					xp: 35,
					rules: [
						'Fin must be a single ring',
						'May be cylindrical or polyhedral',
						'Do not confuse this with the Tube Fins task'
					]
				},
				'tube-fins': {
					name: 'Tube Fins',
					desc: 'Build and fly a rocket with a tube fins',
					xp: 15,
					rules: [
						'Must have multiple tubes for fins, may also have "normal" fins',
						'Do not confuse this with the Ring Fin task.'
					]
				},
				'2stagecomp': {
					name: 'Two Stager (Composite)',
					desc: 'Build and fly a 2-stage rocket on composite motors',
					xp: 100,
					rules: [
						'Stages must separate cleanly and as intended',
						'Electronic ignition of the 2nd stage is required',
						'The second stage must ignite properly as intended',
						'Both stages must be recovered',
						'"Composite" means any non-black-powder propellant'
					]
				},
				'boosters': {
					name: 'Detachable Boosters',
					desc: 'Build and fly a rocket with detachable boosters',
					xp: 100,
					rules: [
						'Boosters must detach from the rocket at an appropriate time during the flight.',
						'Boosters must be recovered (examiners discretion may be used for exceptional circumstances - simply not-finding the boosters is not permitted)',
						'Booster may be ignited in a staged configuration'
					]
				},
				'non-std': {
					name: 'Non-Standard Components',
					desc: 'Build and fly a rocket build with unusual parts',
					xp: 30,
					rules: [
						'Make use of non-standard components to build a "normal" rocket, e.g. Toblerone box, postal tube',
						'Do not confuse this with the Odd-Roc task'
					]
				},
				'pods': {
					name: 'Pods',
					desc: 'Build and fly a rocket build outboard motor pods',
					xp: 50,
					rules: [
						'Pods must house motors, although a central motor is advised for stability and safety'
					]
				},
				'legged-lander': {
					name: 'Legged-Lander',
					desc: 'Build and fly a rocket which deploys landing legs',
					xp: 65,
					rules: [
						'Legs must be deployed during descent and not be always-out',
						'Rocket must land on its legs, toppling over after landing is permitted'
					]
				}
			}
		},
		'recovery': {
			name: 'Recovery',
			color: 'dark-purple',
			tasks: {
				'parachute': {
					name: 'Parachute',
					desc: 'Recover a rocket by parachute',
					rules: [
						'Parachute must appropriately sized',
						'Parachute must properly deploy',
						'Parachute must be in reusable condition after flight',
						'Rocket must descend at a safe speed'
					],
					xp: 2
				},
				'streamer': {
					name: 'Streamer',
					desc: 'Recover a rocket by streamer',
					rules: [
						'Streamer must properly deploy',
						'Streamer must be in reusable condition after flight',
						'Rocket must descend at a safe speed'
					],
					xp: 1
				},
				'tumble': {
					name: 'Tumble',
					desc: 'Recover a rocket by tumbling',
					rules: [
						'Rocket must safely tumble and not return ballistically'
					],
					xp: 1
				},
				'glider': {
					name: 'Glider',
					desc: 'Recover using wings',
					xp: 10,
					fullDesc: 'Build and fly a rocket glider',
					rules: [
						'Do not confuse this with the Detachable Glider task',
						'Glider must have a stable flight on ascent and descent',
						'Glider must safely land'
					]
				},
				'monocopter': {
					name: 'Monocopter',
					desc: 'Flutter down like a sycamore seed',
					xp: 10
				},
				'heli': {
					name: 'Helicopter',
					desc: 'Use blades to spin back to earth',
					xp: 30
				},
				'detachable-glider': {
					name: 'Detachable Glider',
					desc: 'Deploy a glider which flies down separately',
					xp: 30
				},
				'rc-glider': {
					name: 'R/C Glider',
					desc: 'Fly a remote-controlled glider',
					xp: 65,
					rules: [
						'Can be a rocket glider or a separately detached glider',
						'Glider must have remote-controlled aerodynamic surfaces to enable it to be flown',
						'Flight and landing must be controlled, stable and safe'
					]
				},
				'dual-deploy': {
					name: 'Dual Deploy',
					desc: 'Build and fly a dual-deployment rocket',
					xp: 50,
					rules: [
						'Use electronics to deploy your drogue/main parachute.',
						'A drogue parachute is not required, but you must separate your rocket at apogee'
					]
				},
				'multiple-chutes': {
					name: 'Multiple Parachutes',
					desc: 'Recover a rocket using multiple parachutes',
					xp: 5,
					rules: [
						'This does not mean dual-depoy, this means use two smaller parachutes instead of one larger',
						'Both parachutes must deploy properly'
					]
				},
				'two-parts': {
					name: 'Separate Parts',
					desc: 'Recover a rocket in multiple parts',
					xp: 5,
					rules: [
						'Parts must not be attached together',
						'Each part must be recovered by its own parachute',
						'Booster sections or detachable boosters/gliders do not count'
					]
				},
				'diy-nylon-chute': {
					name: 'DIY Nylon Parachute',
					desc: 'Build and fly a home-made ripstop nylon parachute',
					xp: 50,
					rules: [
						'May be any polygon shape',
						'May include a spill hole',
						'All seams and edges are to be stitched and the shroud lines are also to be stitched to the parachute',
						'The parachute must be used to successfully recover a rocket',
						'The parachute must survive undamaged - normal wear and tear excepted.'
					]
				},'powered-landing': {
					name: 'Land under thrust',
					desc: 'Use a rocket motor to control descent and land',
					xp: 500,
					rules: [
						"Requires Safety and Technical comittee oversight"
					]
				},
				'mechanical-release': {
					name: 'Mechanical Release',
					desc: 'Release parachute using a mechancical method',
					rules: [
						'Hot wire cutter may be used',
						'Mechanical catch release (e.g. Jolly Logic) may be used',
						'Parachute must release and deploy properly'
					],
					xp: 2
				},
			}
		},
		'mission': {
			name: 'Mission',
			color: 'dark-blue',
			tasks: {
				'payload': {
					name: 'Non-fragile Payload',
					desc: 'Deploy a non-fragile payload at apogee',
					xp: 10,
					rules: [
						'May be one or multiple payloads.',
						'The rocket must contain and completely enclose the payload.',
						'The payload is to be released from the rocket at apogee and descend safely',
						'Payloads must have their own recovery devices',
						'The payload must be easily removed from the rocket for inspection'
					]
				},
				'altimeter': {
					name: 'Altimeter',
					desc: 'Fly an altimeter and log apogee',
					xp: 10,
					rules: [
						'The altimeter must be a commercially available, off-the-shelf altimeter and not home made',
						'The flight log must be submitted to UKRA',
						'Do not confuse this task with Dual-Deployment, the altimeter must only be used for logging although it may be a dual-deploy capable device'
					]
				},
				'diy-altimeter': {
					name: 'DIY Altimeter',
					desc: 'Fly a home-made altimeter',
					xp: 50,
					rules: [
						'The altimeter must be passive and not control any aspect of the flight',
						'The flight log must be submitted to UKRA',
						'Do not confuse this task with Dual-Deployment, the altimeter must only be used for logging although it may be a dual-deploy capable device'
					]
				},
				'camera': {
					name: 'Camera',
					desc: 'Fly with an onboard camera',
					xp: 15,
					rules: [
						'The camera may take stills or record video',
						'The camera may be inside the rocket body viewing out of a hole in the airframe, or be in a fairing outside of the body viewing in any direction',
						'Temporarilly attaching a camera to the outside of a rocket, or attaching it to the shock cord or similar does not qualify, it must be properly enclosed',
						'The MAP examiner does not need to view the recorded imagery, but the camera should be shown to be on and working'
					]
				},
				'egg': {
					name: 'Fragile Payload',
					desc: 'Deploy a raw egg at apogee and recover it in-tact',
					xp: 30,
					rules: [
						'Egg must be ejected from the rocket and recovered separately.',
						'Egg must be unbroken on landing (and then proved to not be hard boiled)'
					]
				},
				'cansat': {
					name: 'Cansat',
					desc: 'Deploy and recover an electronic payload',
					xp: 50,
					rules: [
						'Must be ejected from the rocket and recover separately',
						'Trackers do not qualify, although it may contain a tracker',
						'Electronics must perform some function, e.g. log data, play music'
					]
				},
				'altitude': {
					name: 'Altitude Attempt',
					desc: 'Fly a rocket to apogee at a specific altitude',
					xp: 40,
					rules: [
						'Commercial, off-the-shelf altimeter must be used',
						'Rocket must come within 10% of target altitude'
					]
				},
				'diy-flight-computer': {
					name: 'DIY Flight Computer',
					desc: 'Design, build and fly your own flight computer',
					xp: 100,
					rules: [
						"Requires Safety and Technical comittee oversight"
					]
				}
			}
		},
		'launch': {
			name: 'Launch Control',
			color: 'dark-green',
			tasks: {
				'piston': {
					name: 'Piston Launcher',
					desc: 'Launch with a piston launcher',
					rules: [
						'Piston mechanism must function properly'
					],
					xp: 10
				},
				'tube': {
					name: 'Tube Launcher',
					desc: 'Launch a lug-less rocket from within a tube',
					rules: [
						'Rocket must have no launch-lugs or rail guides and be guided by the tube'
					],
					xp: 20
				},
				'tower': {
					name: 'Tower Launcher',
					desc: 'Launch a lug-less rocket from a guide tower',
					rules: [
						'Rocket must have no launch-lugs or rail guides and be guided by the tower'
					],
					xp: 20
				},
				'cradle': {
					name: 'Cradle Launcher',
					desc: 'Launch a lug-less rocket using a cradle',
					rules: [
						'Rocket must have no launch-lugs or rail guides',
						'Cradle/rail-guide should self-detach once the rocket has left the pad'
					],
					xp: 35
				},
				'controller': {
					name: 'Launch Controller',
					desc: 'Build your own launch controller',
					xp: 75,
					rules: [
						"Requires Safety and Technical comittee oversight"
					]
				}
			}
		}
	},
	levels: [
		{
			id: 'recruit',
			name: 'Recruit',
			xpRequired: 15,
			tasks: [
				'bonus.diy-plastic-chute',
				'propulsion.A/4',
				'propulsion.A/2',
				'propulsion.A',
				'construction.rtf',
				'construction.assembly',
				'construction.cone',
				'construction.kit',
				'recovery.parachute',
				'recovery.streamer',
				'recovery.tumble'
			]
		},
		{
			id: 'cadet',
			name: 'Cadet',
			xpRequired: 55,
			tasks: [
				'bonus.swing',
				'bonus.drag-race',
				'bonus.papering',
				'propulsion.B',
				'propulsion.C',
				'construction.pyramid',
				'construction.scratch',
				'construction.2stagebp',
				'construction.clusterbp',
				'recovery.two-parts',
				'recovery.multiple-chutes'
			]
		},
		{
			id: 'pilot',
			name: 'Pilot',
			xpRequired: 150,
			tasks: [
				'bonus.sim',
				'bonus.baffle',
				'bonus.record',
				'bonus.canard',
				'propulsion.D',
				'propulsion.E',
				'propulsion.clustercomp18',
				'construction.scale',
				'construction.upscale',
				'construction.downscale',
				'recovery.glider',
				'recovery.monocopter',
				'mission.payload',
				'mission.altimeter',
				'launch.piston'
			]
		},
		{
			id: 'flight-engineer',
			name: 'Flight Engineer',
			xpRequired: 335,
			tasks: [
				'bonus.aerotech',
				'bonus.cti',
				'bonus.3dprinting',
				'bonus.spin-stabilised',
				'propulsion.F',
				'propulsion.clustercomp24',
				'construction.oddroc',
				'construction.non-std',
				'construction.tube-fins',
				'recovery.heli',
				'recovery.detachable-glider',
				'mission.egg',
				'mission.camera',
				'launch.tube',
				'launch.tower',
			]
		},
		{
			id: 'mission-specialist',
			name: 'Mission Specialist',
			xpRequired: 705,
			tasks: [
				'bonus.piston-ejection',
				'bonus.fibreglass',
				'bonus.carbonfibre',
				'propulsion.G',
				'construction.ring-fin',
				'construction.pods',
				'construction.legged-lander',
				'recovery.rc-glider',
				'recovery.dual-deploy',
				'recovery.diy-nylon-chute',
				'mission.cansat',
				'mission.altitude',
				'launch.cradle'
			]
		},
		{
			id: 'commander',
			name: 'Commander',
			tasks: [
				'propulsion.micro-hybrid',
				'construction.boosters',
				'construction.2stagecomp',
				'mission.diy-flight-computer',
				'recovery.powered-landing',
				'launch.controller'
			]
		}
	],
	bonuses: {
		'construction.whirly': 2,
		'construction.3stagebp': 50,
		'recovery.mechanical-release': 150,
		'mission.diy-altimeter': 250
	}
}

let data = null;

function expand(input)
{
	let subjectsArray = Object.keys(input.subjects).map((k, i) => {

		input.subjects[k].index = i;

		return {
			id: k,
			name: input.subjects[k].name,
			color: input.subjects[k].color
		}
	});

	return {
		//placeholder
		badges: [],
		subjects: subjectsArray,
		levels: input.levels.map(l => ({
			id: l.id,
			name: l.name,
			xpRequired: l.xpRequired,
			subjects: subjectsArray.map(subject => {

				let tasks = l.tasks.map(t => {
					let path = t.split('.')

					if(path[0] != subject.id)
						return null;

					let task = input.subjects[subject.id].tasks[path[1]]

					if(task == null)
					{
						console.error(`Cant find ${t}`)
						return null;
					}
					
					return {
						id: t,
						taskId: path[1],
						task
					};
				}).filter(t => t);

				return {
					id: subject.id,
					name: subject.name,
					bgColor: subject.color,
					tasks: tasks.map(t => {
						return {
							id: t.id,
							name: t.task.name,
							desc: t.task.desc,
							rules: t.task.rules,
							xp: t.task.xp,
							hydrazine: t.task.hydrazine,
							taskId: t.taskId,
							bgColor: subject.color
						}
					})
				}
			})
		})),
		bonuses: Object.entries(input.bonuses).map(([t, requiredHydrazine]) => {
			let path = t.split('.')

			let task = input.subjects[path[0]].tasks[path[1]]
			if(task == null)
			{
				console.error(`Cant find ${t}`)
				return null;
			}
			
			return {
				id: t,
				taskId: path[1],
				subject: input.subjects[path[0]],
				task,
				requiredHydrazine
			};
		}).filter(t => t).map(t => {
			return {
				id: t.id,
				name: t.task.name,
				subject: t.subject,
				desc: t.task.desc,
				rules: t.task.rules,
				xp: t.task.xp,
				hydrazine: t.task.hydrazine,
				taskId: t.taskId,
				bgColor: t.subject.color,
				requiredHydrazine: t.requiredHydrazine
			}
		})
		
	}
}

export function initMap()
{
	if(data != null)
		return;

	data = expand(raw);
}

export function getMap(user)
{
	initMap();

	if(user == null)
		return data;

	let userData = expand(JSON.parse(JSON.stringify(raw)));

	let userLevel = user.map.level;
	let userCompletedTasks = [];
	let userBadges = [];

	let userRequiredXP = 1;

	let found = false;
	for(let l = 0; l < userData.levels.length; l++)
	{
		let level = userData.levels[l];

		for(let s = 0; s < level.subjects.length; s++)
		{
			let subject = level.subjects[s];

			for(let t = 0; t < subject.tasks.length; t++)
			{
				let task = subject.tasks[t];
				if(found)
				{
					// disable future tasks
					//task.bgColor = 'grey'
					task.mode = 'future'
				}
				else if(level.id != userLevel)
				{
					task.mode = 'previous'
				}
				else
				{
					task.mode = 'current'
				}

				task.complete = false;

				if(user.map.completedTasks.findIndex(ct => ct == task.id) > -1)
				{
					// dont mark hydrazine tasks as complete, as theyre repeatable
					task.complete = subject.id != 'bonus';

					userCompletedTasks.push({
						bgColor: subject.bgColor,
						taskId: task.taskId,
						name: task.name,
						xp: task.xp,
						hydrazine: task.hydrazine
					})

					if(task.xp)
						task.bgColor = 'light'
				}

				let badgeTask = user.map.badges.find(b => b.taskId == task.id)
				if(badgeTask)
				{
					userBadges.push({
						bgColor: 'purple',
						taskId: task.taskId,
						name : badgeTask.type == 'first' ? 'First person to complete ' + task.name : 'TODO'
					})
				}
			}
		}

		if(level.id == userLevel)
		{
			found = true;
			userRequiredXP = level.xpRequired;
		}
	}
	userCompletedTasks.sort((a, b) => b.points - a.points)
	userData.completedTasks = userCompletedTasks;

	userData.xpRequired = userRequiredXP;

	for(let t = 0; t < userData.bonuses.length; t++)
	{
		let task = userData.bonuses[t];

		task.unlocked = user.map.hydrazine >= task.requiredHydrazine;
		task.bgColor = task.unlocked ? task.bgColor : 'grey'

		if(!userData.hydrazineRequired)
		{
			if(task.requiredHydrazine > user.map.hydrazine)
			{
				userData.hydrazineRequired = task.requiredHydrazine
			}
		}

		task.complete = false;

		if(user.map.completedTasks.findIndex(ct => ct == task.id) > -1)
		{
			// dont mark hydrazine tasks as complete, as theyre repeatable
			task.complete = task.subject.id != 'bonus';

			userCompletedTasks.push({
				bgColor: task.subject.color,
				taskId: task.taskId,
				name: task.name,
				xp: task.xp,
				hydrazine: task.hydrazine
			})

			if(task.xp)
				task.bgColor = 'light'
		}
	}

	// TODO: non-task badges

	userData.badges = userBadges;
	return userData;
}

export function applyMapTasks(user, tasks, scoreboard)
{
	let validTasks = [];
	let badges = [];

	for(let l = 0; l < raw.levels.length; l++)
	{
		let level = raw.levels[l];

		for(let t = 0; t < level.tasks.length; t++)
		{
			let task = level.tasks[t];

			if(tasks.find(t => t == task))
			{
				// allowed task (prev or current level)

				let parts = task.split('.');
				let fullTask = raw.subjects[parts[0]].tasks[parts[1]]

				if(user.map.completedTasks.find(ct => ct == task) && parts[0] != 'bonus')
				{
					// already completed
				}
				else
				{
					// new task


					validTasks.push({
						id: task,
						xp: fullTask.xp || 0,
						hydrazine: fullTask.hydrazine || 0
					})
				}
			}
		}

		if(level.id == user.map.level)
			break;
	}

	// badges
	let firstTasks = validTasks.filter(t => !scoreboard.isTaskCompleted(t.id)).map(t => ({
		type: 'first',
		taskId: t.id
	}))
	badges = badges.concat(firstTasks);

	// levels
	let taskXP = validTasks.reduce((p, c) => p + c.xp, 0);

	return {
		tasks: validTasks.map(t => t.id),
		xp: taskXP,
		hydrazine: validTasks.reduce((p, c) => p + c.hydrazine, 0),
		badges
	}
}

export function getLevelForXP(xp)
{
	let newLevel = 'recruit';

	for(let l = raw.levels.length - 1; l >= 0; l--)
	{
		if(xp >= raw.levels[l].xpRequired)
		{
			newLevel = raw.levels[l + 1].id
			break;
		}
	}

	return newLevel;
}

export function getBonusTasksForHydrazine(hydrazine)
{
	initMap();
	return data.bonuses.filter(t => t.requiredHydrazine <= hydrazine);
}

export function getLevelName(id)
{
	return raw.levels.find(l => l.id == id).name
}