import { getFirestore, doc, getDoc, collection, getDocs, query, onSnapshot, where, documentId } from 'firebase/firestore'
import { convertFromFirestore } from '../../../functions/lib/automapper.js'
import { explainError } from '../../../functions/lib/persistence.js';
import { chunk } from '../../../functions/lib/arrayFunctions.js';

export async function getProjection(pathSegments)
{
	let db = getFirestore();
	let path = firestorePath(pathSegments);
	let ref = doc(db, path);
	
	let snap = null;
	try
	{
		snap = await getDoc(ref);
	}
	catch(e)
	{
		throw explainError(e, `Failed to get ${path}`);
	}

	let data = snap.exists() ? convertFromFirestore(snap.data()) : null;
	return data;
}

/**
 * Get all projections in a collection
 * @param {[string]} pathSegments Path segments to the collection
 * @returns 
 */
export async function getAllProjectionsInCollection(pathSegments)
{
	let db = getFirestore();
	let path = firestorePath(pathSegments);
	let ref = collection(db, path);
	let snap = null;
	try
	{
		snap = await getDocs(ref)
	}
	catch(e)
	{
		throw explainError(e, `Failed to get ${path}`);
	}
	return snap.docs.map(d => convertFromFirestore(d.data()));
}

export async function findProjection(pathSegments, where)
{
	let db = getFirestore();
	let path = firestorePath(pathSegments);
	let ref = collection(db, path);
	let q = query(ref, where);
	let snap = null;
	try
	{
		snap = await getDocs(q);
	}
	catch(e)
	{
		throw explainError(e, `Failed to query ${path}`);
	}

	if(snap.docs.length == 1)
		return convertFromFirestore(snap.docs[0].data());
	return null;
}

export async function findProjections(pathSegments, wheres)
{
	let db = getFirestore();
	let path = firestorePath(pathSegments);
	let ref = collection(db, path);
	if(!Array.isArray(wheres))
		wheres = [wheres]
	let q = query(ref, ...wheres);
	let snap = null;
	try
	{
		snap = await getDocs(q);
	}
	catch(e)
	{
		throw explainError(e, `Failed to query ${path}`);
	}

	let results = snap.docs.map(result => convertFromFirestore(result.data()))
	return results;
}

export async function getProjectionsById(pathSegments, ids)
{
	if(ids.length == 0)
		return [];

	let db = getFirestore();
	let path = firestorePath(pathSegments);
	let ref = collection(db, path);

	let idChunks = chunk(ids, 10);
	
	// TODO: try catch
	let chunkedSnapshots = await Promise.all(idChunks.map(ids => getDocs(query(ref, where(documentId(), 'in', ids)))))
	let projections = chunkedSnapshots.flatMap(snap => snap.docs.map(doc => convertFromFirestore(doc.data())))
	return projections;
}

export async function listenToSingletonProjection(type, callback)
{
	return listenToProjection(`singletons/${type.name}`, callback)
}

export async function listenToProjection(pathSegments, callback)
{
	let db = getFirestore();
	let path = firestorePath(pathSegments);
	let ref = doc(db, path);
	let firstCall = true;
	return new Promise((resolve, reject) => {
		const unsubscribe = onSnapshot(ref, async doc => {
			let data = convertFromFirestore(doc.data())

			// BUG: this breaks signup where the auth state changes but the user[view/lite] hasnt yet been created
			// if(firstCall && !data)
			// {
			// 	reject(`${path} not found (null)`);
			// 	unsubscribe();
			// 	return;
			// }

			await Promise.resolve(callback(data, firstCall));
			if(firstCall)
			{
				resolve(unsubscribe);
				firstCall = false;
			}
		}, e => {
			throw explainError(e, `Listening to document ${path}`);
		});
	});
}

export async function listenForProjections(pathSegments, array)
{
	let db = getFirestore();
	let ref = collection(db, firestorePath(pathSegments));

	const unsubscribe = onSnapshot(ref, snapshot => {
		snapshot.docChanges().forEach(change => {
			const { newIndex, oldIndex, doc, type } = change
			if (type === 'added') {
				array.splice(newIndex, 0, convertFromFirestore(doc.data()))
				// if we want to handle references we would do it here
			} else if (type === 'modified') {
				// remove the old one first
				array.splice(oldIndex, 1)
				// if we want to handle references we would have to unsubscribe
				// from old references' listeners and subscribe to the new ones
				array.splice(newIndex, 0, convertFromFirestore(doc.data()))
			} else if (type === 'removed') {
				array.splice(oldIndex, 1)
				// if we want to handle references we need to unsubscribe
				// from old references
			}
		});
	}, e => {
		throw explainError(e, `Listening to collection ${ref}`);
	});

	return unsubscribe;
}

export async function listenForFoundProjections(pathSegments, where, array)
{
	let db = getFirestore();
	let ref = collection(db, firestorePath(pathSegments));
	let q = query(ref, where);

	const unsubscribe = onSnapshot(q, snapshot => {
		snapshot.docChanges().forEach(change => {
			const { newIndex, oldIndex, doc, type } = change
			if (type === 'added') {
				array.splice(newIndex, 0, convertFromFirestore(doc.data()))
				// if we want to handle references we would do it here
			} else if (type === 'modified') {
				// remove the old one first
				array.splice(oldIndex, 1)
				// if we want to handle references we would have to unsubscribe
				// from old references' listeners and subscribe to the new ones
				array.splice(newIndex, 0, convertFromFirestore(doc.data()))
			} else if (type === 'removed') {
				array.splice(oldIndex, 1)
				// if we want to handle references we need to unsubscribe
				// from old references
			}
		});
	}, e => {
		throw explainError(e, `Listening to collection ${ref}`);
	});

	return unsubscribe;
}

export function firestorePath(pathSegments)
{
	if(typeof pathSegments == 'string')
		return pathSegments;

	let segemnts = pathSegments.map((a, i) => {
		if(i % 2 == 0)
			return a.name;
		return a;
	});

	return segemnts.join('/');
}