<script>
export const pageName = 'rso';
export const pageRoute = '/rso/{rsoLevel}';
export const title = 'RSO Certification'
export const requireMembership = true;
export const requireCompleteAccount = true;

import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/lib/functions.js';
import { navigate } from '@trullock/page-manager'
import { showToast } from '../../js/utils/toast.js'
import UserView from '../../../functions/domain/users/userView.js';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { renderRSOCert } from '../../js/utils/renderer.js'
import { rsoLevelToLevel } from '../../../functions/domain/certification-utils.js'

export default {
	data() {
		return {
			currentUser: null,
			certImg: null,
			ready: false
		}
	},

	methods: {
		rsoLevelToLevel,
		async boot(opts) {
			await listenToProjection([UserView, getCurrentUserId()], async user => {
				this.currentUser = user;
				this.certImg = await renderRSOCert(user);
			});	
			this.ready = true;

		},
		show() {
			functions.warmUp.userRequestRSOInterview()
		},
		async submit() {
			
			showLoading();

			let result = await functions.userRequestRSOInterview();
			
			if(!result.success)
			{
				showToast({ message: 'Error submitting application', style: 'bg-danger'})
				hideLoading();
				return;
			}

			navigate('/');

			showToast({
				message: 'Application submitted',
				style: 'bg-success'
			})

			hideLoading();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">
			<div class="col col-12">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">RSO Certification</h4>
					</div>
					<div class="card-body">
						<p>To certify as a UKRA Certified Range Safety Officer, the member must successfully complete a RSO Interview.</p>
						<p>The interview will be conducted by two UKRA officials, at least one of these officials will be a member of the Chair (or nominated representative) of the UKRA Safety and Technical Committee (the other may be a UKRA Range Safety Officer of a certification level at least that of the candidate). The interview will concentrate on issues such as rocketry experience, technical and safety issues for managing the flightline as the RSO and the role of Flight Certification Officer in the certification process. The interview will be informal and draw on questions from the FCO guidance document. An applicant will be informed of the result of the interview as soon as possible.</p>
						
						<p>Detailed information about the <a href="https://www.ukra.org.uk/certification/range-safety-officer/" target="_blank">RSO Certification Process <span class="fe fe-external-link"></span></a> can be found on the main UKRA website.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col col-12 col-lg-8">
				<div class="card" v-if="currentUser.rso.status == 'passed'">
					<div class="card-header">
						<h4 class="card-header-title">Complete</h4>
					</div>
					<div class="card-body">
						<p>Congratulations! You've passed your RSO certification.</p>
						<p>Why not order a certificate to hang in your rocket workshop?</p>
						<p><a href="/certification/buy-a-certificate/rso" class="btn btn-primary btn-block">Order a certificate <span class="fe fe-award"></span></a></p>
						<p class="mb-0">Alternatively, you can <a :download="`rso-cert.png`" :href="certImg" target="_blank">download a certificate to print at home</a>.</p>
					</div>
				</div>
				<div class="card" v-if="!currentUser.rso.mode || (currentUser.rso.mode == 'explicit' && currentUser.certification.level != currentUser.rso.level)">
					<div class="card-header">
						<h4 class="card-header-title">Application</h4>
					</div>
					<div class="card-body">
						<form ref="form" @submit.prevent="submit">
							<p v-if="currentUser.rso.mode == 'explicit'">You are currently limited to {{ rsoLevelToLevel(currentUser.rso.level) }} RSO. To be granted a higher level please apply below.</p>
							<p class="alert alert-light small">Please note: If you have recently applied and not heard back, please wait a short while. UKRA is staffed by volunteers on a part time basis and will respond to your enquiry as soon as possible.</p>
							<button type="submit" class="btn btn-lg btn-primary btn-block">Apply <span class="fe fe-check-circle"></span></button>
						</form>
					</div>
				</div>
			</div>
			<div class="col col-12 col-lg-4">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Progress</h4>
					</div>
					<div class="card-body">
						<div class="text-secondary small mb-4">Complete one interview for RSO.</div>
						<div class="list-group list-group-flush my-n3">
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Interview
									</div>
									<div class="col-auto">
										<div :class="`badge ${currentUser.rso.status == 'passed' ? 'badge-success' : 'badge-light'}`">
											{{ currentUser.rso.status == 'passed' ? 'Passed' : 'Not passed' }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
