<script>
export const pageName = 'map';
export const pageRoute = '/model-achievement-programme';
export const title = 'Model Achievement Programme'
export const requireAuth = false

import { getMap } from '../../../functions/domain/map/map.js';
import { getCurrentUserId } from '../../js/auth.js';
import { listenToMapScoreboard, listenToUserView } from '../../js/readModel.js';
import { showConfirm} from '../../js/utils/confirm.js'
import { getUserMap } from '../../js/utils/map.js';
export default {
	data() {
		return {
			currentUser: null,
			data: null,
			scoreboard: null,
			backgrounds: {},
			ready: false
		}
	},

	methods: {
		async boot(opts) {

			// allow anon visitors from main website
			if(getCurrentUserId())
			{
				await listenToUserView(getCurrentUserId(), async user => {
					this.currentUser = user;
					this.data = await getUserMap(this.currentUser);
				});
			}
			else
			{
				this.data = await getUserMap(null);
			}

			await listenToMapScoreboard(scoreboard => this.scoreboard = scoreboard);

			this.ready = true;
		},
		showTask(task)
		{
			showConfirm({
				title: task.name,
				message: `
<h4>${task.desc}</h4>
<p>Rules:</p>
<ul>
${task.rules?.map(r => `<li>${r}</li>`).join('\n')}
</ul>
`,
				buttons: [
					{
						text: 'Close',
						style: 'primary'
					}
				]
			})
		},
		show() {
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<h1 class="display-4 text-center mb-3">Model Achievement Programme</h1>
		<p class="text-muted text-center mb-5">The Model Achievement Programme is a fantastic opportunity to develop your skills as a rocket modeller by completing a series of increasingly difficult and complicated challenges. Please see <a href="https://ukra.org.uk/certification/model-achievement-programme/" target="_blank">the main UKRA website <span class="fe fe-external-link"></span></a> for a full description of the programme.</p>
				
		<h1 class="mb-4 text-center">Leader board</h1>
		<div class="row">
			<div class="col-12 col-md-6">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">XP</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush my-n3">
							<div v-for="(xper, index) in scoreboard.xpScoreboard" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<span class="display-4">{{ index + 1}}</span>
									</div>	
									<div class="col-auto">
										<div class="avatar avatar-sm">
											<img class="avatar-img rounded-circle" :src="xper.userAvatar">
										</div>
									</div>
									<div class="col">
										<h4 class="mb-1">{{ xper.userName }}</h4>
										<small class="text-muted">{{ xper.xp  }} XP</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-6">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Hydrazine</h4>
					</div>
					<div class="card-body">
						<div class="list-group list-group-flush my-n3">
							<div v-for="(xper, index) in scoreboard.hydrazineScoreboard" class="list-group-item">
								<div class="row align-items-center">
									<div class="col-auto">
										<span class="display-4">{{ index + 1}}</span>
									</div>	
									<div class="col-auto">
										<div class="avatar avatar-sm">
											<img class="avatar-img rounded-circle" :src="xper.userAvatar">
										</div>
									</div>
									<div class="col">
										<h4 class="mb-1">{{ xper.userName }}</h4>
										<small class="text-muted">{{ xper.hydrazine  }} Hydrazine</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="text-center">
			<hr class="my-5">
			<h1 class="mb-2">Bonuses</h1>
			<p class="text-muted mb-5">Unlock these tasks by earning Hydrazine.</p>
			<div class="row">
				<div v-for="task in data.bonuses" class="col-12 col-sm-6 col-md-4 col-lg-3">
					<a v-if="task.unlocked" :href="`#${task.id}`" :id="task.id" @click.prevent="showTask(task)" class="card card-fill card-fill-top">
						<div class="card-body">
							<div class="d-inline-block" :style="`background: url(${task.bg}); ${task.mode == 'future' ? 'opacity: 0.5' : ''}`">
								<img :src="task.icon" />
							</div>
							<h2 :class="`${task.mode == 'future' ? 'text-muted' : 'text-body'}`">{{ task.name }}</h2>
							<div class="text-muted">{{ task.desc }}</div>
							<span v-if="task.xp && !task.complete" :class="`badge badge-white border border-1 shadow font-weight-bold`" style="position: absolute; top: -5px; right: -5px; font-size: 90%">+{{ task.xp }} XP</span>
							<span v-if="task.xp && task.complete" :class="`badge badge-success border border-1 shadow font-weight-bold`" style="position: absolute; top: -5px; right: -5px; font-size: 90%">Complete</span>
							<span v-if="task.hydrazine" :class="`badge ${task.mode == 'future' ? 'badge-white' : 'badge-soft-danger'} shadow font-weight-bold`" style="position: absolute; top: -5px; right: -5px; font-size: 90%">+{{ task.hydrazine }} Hydrazine</span>
						</div>
					</a>
					<a v-else class="card card-fill card-fill-top">
						<div class="card-body">
							<div class="d-inline-block" :style="`background: url(${task.bg}); ${task.mode == 'future' ? 'opacity: 0.5' : ''}`">
								<img :src="task.icon" />
							</div>
							<h2 :class="`${task.mode == 'future' ? 'text-muted' : 'text-body'}`">Locked</h2>
							<div class="text-muted">Earn Hydrazine to unlock this task</div>
							<span class="badge badge-soft-danger shadow font-weight-bold" style="position: absolute; top: -5px; right: -5px; font-size: 90%">{{ task.requiredHydrazine }} Hydrazine required</span>
						</div>
					</a>
				</div>
			</div>
		</div>
		<div class="text-center" v-for="(level, i) in data.levels">
			<hr class="my-5">
			<h1 class="mb-2">{{ level.name }}</h1>
			<span v-if="level.id == currentUser.map.level" class="badge badge-primary shadow font-weight-bold mb-2">Your current rank</span>
			<p v-if="level.xpRequired" class="text-muted mb-5">You need {{ level.xpRequired }} XP to graduate from this rank.</p>
			<p v-else class="text-muted mb-5">This is the highest rank within MAP.</p>
			<div class="row">
				<template v-for="subject of data.levels[i].subjects">
					<template v-if="subject.tasks.length">
						<div v-for="task in subject.tasks" class="col-12 col-sm-6 col-md-4 col-lg-3">
							<a :href="`#${task.id}`" :id="task.id" @click.prevent="showTask(task)" class="card card-fill card-fill-top">
								<div class="card-body">
									<div class="d-inline-block" :style="`background: url(${task.bg}); ${task.mode == 'future' ? 'opacity: 0.5' : ''}`">
										<img :src="task.icon" />
									</div>
									<h2 :class="`${task.mode == 'future' ? 'text-muted' : 'text-body'}`">{{ task.name }}</h2>
									<div class="text-muted">{{ task.desc }}</div>
									<span v-if="task.xp && !task.complete" :class="`badge badge-white border border-1 shadow font-weight-bold`" style="position: absolute; top: -5px; right: -5px; font-size: 90%">+{{ task.xp }} XP</span>
									<span v-if="task.xp && task.complete" :class="`badge badge-success border border-1 shadow font-weight-bold`" style="position: absolute; top: -5px; right: -5px; font-size: 90%">Complete</span>
									<span v-if="task.hydrazine" :class="`badge ${task.mode == 'future' ? 'badge-white' : 'badge-soft-danger'} shadow font-weight-bold`" style="position: absolute; top: -5px; right: -5px; font-size: 90%">+{{ task.hydrazine }} Hydrazine</span>
								</div>
							</a>
						</div>
					</template>
				</template>
			</div>
		</div>
    </div>
</template>
