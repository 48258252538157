<script>
import { functions } from '../../js/lib/functions.js'
import { navigate } from '@trullock/page-manager'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { showToast } from '../../js/utils/toast.js';
import { showConfirm } from '../../js/utils/confirm.js'
import { getAdminSummaryReport } from '../../js/readModel.js'
import DateTime from '../../../functions/lib/dateTime.js';

export const pageName = 'admin-reportss';
export const pageRoute = '/admin/reports';
export const title = 'Reports'
export const requireAdmin = true;

export default {
	data() {
		return { 
			user: null,
			report: null,
			ready: false,
			DateTime,
		}
	},
	methods: {
		async show(opts)
		{
			this.report = await getAdminSummaryReport();
			this.ready = true;
		}
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
	<div class="row justify-content-center py-5">
		<div class="col-12 col-md-8">
			
			<div class="card">
				<div class="card-header">
					<h4 class="card-header-title">This Month</h4>
				</div>
				<table class="table card-table">
					<thead>
						<tr>
							<th scope="col">Event</th>
							<th scope="col" class="shrink">Count</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="[key, value] of Object.entries(this.report.thisYear.months[DateTime.now.month].totals)">
							<td>{{ key }}</td>
							<td>{{ value }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="card">
				<div class="card-header">
					<h4 class="card-header-title">This Year</h4>
				</div>
				<table class="table card-table">
					<thead>
						<tr>
							<th scope="col">Event</th>
							<th scope="col" class="shrink">Count</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="[key, value] of Object.entries(this.report.thisYear.totals)">
							<td>{{ key }}</td>
							<td>{{ value }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="card">
				<div class="card-header">
					<h4 class="card-header-title">Last Year</h4>
				</div>
				<table class="table card-table">
					<thead>
						<tr>
							<th scope="col">Event</th>
							<th scope="col" class="shrink">Count</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="[key, value] of Object.entries(this.report.lastYear.totals)">
							<td>{{ key }}</td>
							<td>{{ value }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>
</template>