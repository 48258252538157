<script>
import { listenToUserView, listenToUserPendingAttempts, listenToUserPendingMAPAttempts } from '../../js/readModel.js';
import { functions } from '../../js/lib/functions.js';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { renderIdCard, renderCert, renderRSOCert, renderIdCardBack } from '../../js/utils/renderer.js'
import { showToast } from '../../js/utils/toast.js'
import { showInput } from '../../js/utils/input.js'
import { showConfirm } from '../../js/utils/confirm.js'
import { rsoLevelToLevel } from '../../../functions/domain/certification-utils.js';
import DateTime from '../../../functions/lib/dateTime.js'
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { navigate, PageShowError } from '@trullock/page-manager'
import { getMembershipImportDate } from '../../../functions/src/site.config.js';
import { getLevelName } from '../../../functions/domain/map/map.js';

export const pageName = 'admin-user-view';
export const pageRoute = '/admin/user/{userId}';
export const title = 'View User'
export const requireAdmin = true;

export default {
	data() {
		return { 
			user: null,
			userMetadata: null,
			pendingAttempts: [],
			pendingMAPAttempts: [],
			idCard: null,
			idCardBack: null,
			l1Cert: null,
			l2Cert: null,
			l3Cert: null,
			rsoCert: null,
			ready: false
		}
	},
	methods: {
		rsoLevelToLevel,
		getLevelName,
		async boot(opts) {
			try
			{
				await listenToUserView(opts.userId, async user => {
					this.user = user;
					
					this.userMetadata = await functions.userGetMetadata({ id: this.user.id })
					
					this.ready = true;

					if(this.user.membership.status == 'active')
					{
						this.idCard = await renderIdCard(this.user);
						this.idCardBack = await renderIdCardBack(this.user);
					}
					
					if(this.user.certification.level1.status == 'passed')
						this.l1Cert = await renderCert(this.user, 'level1');

					if(this.user.certification.level2.status == 'passed')
						this.l2Cert = await renderCert(this.user, 'level2');

					if(this.user.certification.level3.status == 'passed')
						this.l3Cert = await renderCert(this.user, 'level3');
						
					if(this.user.rso.status == 'passed')
						this.rsoCert = await renderRSOCert(this.user);
				});
			}
			catch(e)
			{
				if(e.toString().indexOf('not found (null)') > -1)
					throw new PageShowError('/admin', 'User not found', {}, 'replace');

				throw e;
			}
			
			await listenToUserPendingAttempts(this.user.id, this.pendingAttempts);
			await listenToUserPendingMAPAttempts(this.user.id, this.pendingMAPAttempts);
		},
		async grantL1() {
			functions.warmUp.userCertificationGrant();
			
			showInput({
				title: 'Grant Level 1?',
				message: 'On what date do you want to grant Level 1?',
				input: {
					label: 'Granted on',
					type: 'date',
					required: true,
					max: DateTime.today.format('yyyy-MM-dd'),
					min: '1990-01-01',
					value: DateTime.today.format('yyyy-MM-dd')
				}, 
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Grant',
						style: 'primary',
						action: async value => {
							let result = await functions.userCertificationGrant({
								userId: this.user.id,
								level: 'level1',
								grantedOn: value
							})
							
							if(!result.success)
								showToast({ message: 'Error granting L1', style: 'bg-danger'});
						}
					}
				]
			})
		},
		async revokeL1() {
			showLoading();

			let result = await functions.userCertificationRevoke({
				userId: this.user.id,
				level: 'level1'
			})

			if(!result.success)
				showToast({ message: 'Error revoking L1', style: 'bg-danger'});

			hideLoading();
		},
		async grantL2() {
			functions.warmUp.userCertificationGrant();
			
			
			showInput({
				title: 'Grant Level 2?',
				message: 'On what date do you want to grant Level 2?',
				input: {
					label: 'Granted on',
					type: 'date',
					required: true,
					max: DateTime.today.format('yyyy-MM-dd'),
					min: '1990-01-01',
					value: DateTime.today.format('yyyy-MM-dd')
				}, 
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Grant',
						style: 'primary',
						action: async value => {
							let result = await functions.userCertificationGrant({
								userId: this.user.id,
								level: 'level2',
								grantedOn: value
							})
							
							if(!result.success)
								showToast({ message: 'Error granting L2', style: 'bg-danger'});
						}
					}
				]
			})
		},
		async revokeL2() {
			showLoading();

			let result = await functions.userCertificationRevoke({
				userId: this.user.id,
				level: 'level2'
			})

			if(!result.success)
				showToast({ message: 'Error revoking L2', style: 'bg-danger'});

			hideLoading();
		},
		async grantL3() {
			functions.warmUp.userCertificationGrant();
			
			showInput({
				title: 'Grant Level 3?',
				message: 'On what date do you want to grant Level 3?',
				input: {
					label: 'Granted on',
					type: 'date',
					required: true,
					max: DateTime.today.format('yyyy-MM-dd'),
					min: '1990-01-01',
					value: DateTime.today.format('yyyy-MM-dd')
				}, 
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Grant',
						style: 'primary',
						action: async value => {
							let result = await functions.userCertificationGrant({
								userId: this.user.id,
								level: 'level3',
								grantedOn: value
							})
							
							if(!result.success)
								showToast({ message: 'Error granting L3', style: 'bg-danger'});
						}
					}
				]
			})
		},
		async revokeL3() {
			showLoading();

			let result = await functions.userCertificationRevoke({
				userId: this.user.id,
				level: 'level3'
			})

			if(!result.success)
				showToast({ message: 'Error revoking L3', style: 'bg-danger'});

			hideLoading();
		},
		async grantRSO() {
			functions.warmUp.userRSOGrant();
			
			let buttons = [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
					text: 'Automatic',
					style: 'primary',
					action: async value => {
						let result = await functions.userRSOGrant({
							userId: this.user.id,
							mode: 'implicit',
							grantedOn: value
						})

						if(!result.success)
							showToast({ message: 'Error granting RSO', style: 'bg-danger'});
					}
				},
				{
					text: 'Model',
					style: 'outline-primary',
					action: async value => {
						let result = await functions.userRSOGrant({
							userId: this.user.id,
							level: 'model',
							mode: 'explicit',
							grantedOn: value
						})

						if(!result.success)
							showToast({ message: 'Error granting RSO', style: 'bg-danger'});
					}
				}
			]

			if(this.user.certification.level1.status == 'passed' && this.user.certification.level2.status == 'passed')
			{
				buttons.push({
					text: 'Level 1',
					style: 'outline-primary',
					action: async value => {
						let result = await functions.userRSOGrant({
							userId: this.user.id,
							level: 'level1',
							mode: 'explicit',
							grantedOn: value
						})

						if(!result.success)
							showToast({ message: 'Error granting RSO', style: 'bg-danger'});
					}
				})
			}

			if(this.user.certification.level2.status == 'passed' && this.user.certification.level3.status == 'passed')
			{
				buttons.push({
					text: 'Level 2',
					style: 'outline-primary',
					action: async value => {
						let result = await functions.userRSOGrant({
							userId: this.user.id,
							level: 'level2',
							mode: 'explicit',
							grantedOn: value
						})

						if(!result.success)
							showToast({ message: 'Error granting RSO', style: 'bg-danger'});
					}
				})
			}

			showInput({
				title: 'Grant RSO?',
				message: 'On what date do you want to grant RSO and to which level?<br />"Automatic" will match their Certification level for life.<br/>Otherwise grant a limited level upto and including their certification level.',
				input: {
					label: 'Granted on',
					type: 'date',
					required: true,
					max: DateTime.today.format('yyyy-MM-dd'),
					min: '1990-01-01',
					value: DateTime.today.format('yyyy-MM-dd')
				}, 
				buttons
			})
		},
		async revokeRSO() {
			showLoading();

			let result = await functions.userRSORevoke({
				userId: this.user.id,
			})

			if(!result.success)
				showToast({ message: 'Error revoking RSO', style: 'bg-danger'});

			hideLoading();
		},
		async grantMAPAssessor() {
			functions.warmUp.userMAPAssessorGrant();
			
			showConfirm({
				title: 'Grant Map Assessor?',
				message: 'Are you sure you want to grant this user MAP Assessor?',
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Grant',
						style: 'primary',
						action: async () => {
							let result = await functions.userMAPAssessorGrant({
								userId: this.user.id
							})

							if(!result.success)
								showToast({ message: 'Error granting MAP Assessor', style: 'bg-danger'});
						}
					}
				]
			})
		},
		async grantMembership() {

			functions.warmUp.userMembershipGrant();

			let expiry = new DateTime(DateTime.today.year, 12, 31);
			if(DateTime.today.month == 12)
				expiry = expiry.addYears(1);

			showInput({
				title: 'Grant Membership?',
				message: 'Until what date do you want to grant membership?',
				input: {
					label: 'Expires on',
					type: 'date',
					required: true,
					min: DateTime.today.addDays(1).format('yyyy-MM-dd'),
					value: expiry.format('yyyy-MM-dd')
				}, 
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Grant',
						style: 'primary',
						action: async value => {
							let result = await functions.userMembershipGrant({
								userId: this.user.id,
								expiresOn: value
							})
							
							if(!result.success)
								showToast({ message: 'Error granting membership', style: 'bg-danger'});
							else
								showToast({ message: 'Membership granted', style: 'bg-success'});
						}
					}
				]
			})
		},
		async impersonate() {
			showLoading();

			let result = await functions.userImpersonate({
				id: this.user.id
			})
			
			const auth = getAuth();
			let credential = await signInWithCustomToken(auth, result.token)
			window.location = '/';

			hideLoading();
		},
		async expireMembership() {
			functions.warmUp.userMembershipExpire();

			showConfirm({
				title: 'Confirm expiry',
				message: `Are you sure you want to end this user's membership?`,
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Expire membership',
						style: 'danger',
						action: async () => {
							let result = await functions.userMembershipExpire({ userId: this.user.id });
							if(!result.success)
							{
								showToast({ message: 'Error expiring membership', style: 'bg-danger'})
							}
						}
					}
				]
			})
		},
		async deleteUser() {
			
			functions.warmUp.userDelete();

			showConfirm({
				title: 'Delete User?',
				message: 'Are you sure you want to delete this user? All user data will be erased permanently.',
				buttons: [
					{
						text: 'Cancel',
						style: 'white'
					},
					{
						text: 'Delete',
						style: 'danger',
						action: async () => {
							let result = await functions.userDelete({
								id: this.user.id
							})
							
							if(!result.success)
								showToast({ message: 'Error deleting user', style: 'bg-danger'});

							navigate('/admin');
						}
					}
				]
			})
		},	
		stageBadgeText(stage)
		{
			return stage.status == 'pending' ? 'Pending' : 
				stage.status == 'passed' ? 'Passed' : 
				'Not started'
		},
		stageBadgeClass(stage)
		{
			return stage.status == 'pending' ? 'badge-secondary' : 
				stage.status == 'passed' ? 'badge-success' : 
				'badge-light'
		},
		getMembershipImportDate
	},
	props: [ 'options' ]
}
</script>
<template>
<div class="container" v-if="ready">
    <form ref="form" @submit.prevent="submit">
		<div class="row justify-content-center pt-5">
			<div class="col col-lg-4">
				<div class="card">
					<div class="card-body">

						<div class="avatar avatar-xl card-avatar">
							<img :src="user.avatar" class="avatar-img rounded-circle border border-4 border-card" alt="...">
						</div>

						<h2 class="card-title text-center">
							{{ user.name }}
						</h2>

						<p class="text-center mb-4">
							<span :class="`badge mx-2 ${user.certification.level1.status == 'passed' ? 'badge-success' : (user.certification.level1.status == 'in-progress' ? 'badge-secondary' : 'badge-light') }`">Level 1</span>
							<span :class="`badge mx-2 ${user.certification.level2.status == 'passed' ? 'badge-success' : (user.certification.level2.status == 'in-progress' ? 'badge-secondary' : 'badge-light')}`">Level 2</span>
							<span :class="`badge mx-2 ${user.certification.level3.status == 'passed' ? 'badge-success' : (user.certification.level3.status == 'in-progress' ? 'badge-secondary' : 'badge-light')}`">Level 3</span>
							<span v-if="user.rso.level" class="badge mx-2 badge-success">{{ rsoLevelToLevel(user.rso.level) }} RSO</span>
							<span v-if="user.map.isAssessor" class="badge mx-2 badge-success">MAP Assessor</span>
						</p>

						<p class="text-center mb-4 small">
							Modeller Rank: {{ getLevelName(user.map.level) }}
						</p>
						
						<div class="list-group list-group-flush">
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Joined</small>
									</div>
									<div class="col-auto">
										<time class="small text-body-secondary" :datetime="user.createdOn?.format('yyyy-MM-dd')">
											{{ user.createdOn?.format('yyyy/MM/dd') }}
										</time>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Number</small>
									</div>
									<div class="col-auto">
										<small class="text-body-secondary">
											{{ user.number }}
										</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>BMFA Number</small>
									</div>
									<div class="col-auto">
										<small class="text-body-secondary">
											{{ user.BMFANumber }}
										</small>
									</div>
								</div>
							</div>
							<div v-if="user.rso.level" class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>RSO Mode</small>
									</div>
									<div class="col-auto">
										<small class="text-body-secondary">
											{{ user.rso.mode == 'implicit' ? 'Automatic' : 'Limited' }}
										</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Email</small>
									</div>
									<div class="col-auto">
										<small class="text-body-secondary">
											{{ user.email }} <span :class="`fe ${user.emailVerified ? 'fe-check-circle text-primary' : 'fe-x-circle text-danger'}`"></span>
										</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>D.o.B</small>
									</div>
									<div class="col-auto">
										<small class="text-body-secondary">
											{{ user.bornOn?.format('yyyy/MM/dd') }}
										</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Last Signed In</small>
									</div>
									<div class="col-auto">
										<small class="text-body-secondary">
											{{ userMetadata.lastSignInOn }}
										</small>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row align-items-center">
									<div class="col">
										<small>Auth providers</small>
									</div>
									<div class="col-auto">
										<small class="text-body-secondary">
											{{ userMetadata.providers.map(p => p.providerId).join(', ') }}
										</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h4>Membership</h4>
						<button v-if="user.membership.status != 'active'" @click.prevent="grantMembership" class="btn btn-outline-primary btn-block mt-4">Grant Membership <span class="fe fe-check-circle"></span></button>
						<div v-else>
							<div class="list-group list-group-flush">
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<small>Status</small>
										</div>
										<div class="col-auto">
											<time class="small text-body-secondary">
												{{ user.membership.status }}
											</time>
										</div>
									</div>
								</div>
								<div class="list-group-item">
									<div class="row align-items-center">
										<div class="col">
											<small>Valid until</small>
										</div>
										<div class="col-auto">
											<time class="small text-body-secondary">
												{{ user.membership.expiresOn?.format('yyyy/MM/dd') }}
											</time>
										</div>
									</div>
								</div>
								<div class="list-group-item pb-0">
									<div class="row align-items-center">
										<div class="col">
											<button @click.prevent="expireMembership" class="btn btn-outline-danger btn-sm btn-block">Expire Membership <span class="fe fe-check-circle"></span></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col col-lg-4">
				<div class="card">
					<div class="card-body">
						<a :href="`/admin/user/${user.id}/edit`" class="btn btn-primary btn-block">Edit User <span class="fe fe-edit"></span></a>
						<a :href="`/admin/user/${user.id}/history`" class="btn btn-outline-primary btn-block">View history <span class="fe fe-eye"></span></a>
						<button class="btn btn-outline-primary btn-block" @click.prevent="impersonate">Impersonate <span class="fe fe-check-circle"></span></button>
						<button class="btn btn-outline-danger btn-block" @click.prevent="deleteUser">Delete <span class="fe fe-trash-2"></span></button>
						<hr class="my-5">
						<h4 class="my-4">Certification</h4>
						<button class="btn btn-outline-primary btn-block" @click.prevent="grantL1" v-if="user.certification.level1.status != 'passed' && user.certification.level2.status != 'passed'">Grant Level 1 <span class="fe fe-award"></span></button>
						<button class="btn btn-outline-danger btn-block" @click.prevent="revokeL1" v-if="user.certification.level1.status == 'passed' && user.certification.level2.status != 'passed'">Revoke Level 1 <span class="fe fe-award"></span></button>

						<button class="btn btn-outline-primary btn-block" @click.prevent="grantL2" v-if="user.certification.level2.status != 'passed' && user.certification.level3.status != 'passed'">Grant Level 2 <span class="fe fe-award"></span></button>
						<button class="btn btn-outline-danger btn-block" @click.prevent="revokeL2" v-if="user.certification.level2.status == 'passed' && user.certification.level3.status != 'passed'">Revoke Level 2 <span class="fe fe-award"></span></button>
						
						<button class="btn btn-outline-primary btn-block" @click.prevent="grantL3" v-if="user.certification.level3.status != 'passed'" :disabled="user.age < 18">Grant Level 3 <span class="fe fe-award"></span></button>
						<p v-if="user.age < 18" class="small text-muted text-center mt-2">Must be 18+</p>
						<button class="btn btn-outline-danger btn-block" @click.prevent="revokeL3" v-if="user.certification.level3.status == 'passed'">Revoke Level 3 <span class="fe fe-award"></span></button>
						<hr class="my-5">
						<h4 class="my-4">RSO</h4>
						<button class="btn btn-outline-primary btn-block" @click.prevent="grantRSO" v-if="user.rso.status != 'passed'" :disabled="user.age < 18">Grant RSO <span class="fe fe-check-circle"></span></button>
						<p v-if="user.age < 18" class="small text-muted text-center mt-2">Must be 18+</p>
						<button class="btn btn-outline-danger btn-block" @click.prevent="revokeRSO" v-if="user.rso.status == 'passed'">Revoke RSO <span class="fe fe-check-circle"></span></button>
						<hr class="my-5">
						<h4 class="my-4">MAP</h4>
						<button class="btn btn-outline-primary btn-block" @click.prevent="grantMAPAssessor" v-if="!user.map.isAssessor">Grant Assessor <span class="fe fe-check-circle"></span></button>
						<button class="btn btn-outline-primary btn-block" v-if="user.map.isAssessor" disabled>Grant Assessor <span class="fe fe-check-circle"></span></button>
					</div>
				</div>
			</div>
		</div>
		<hr class="my-4" />
		<div class="row justify-content-center">
			<div class="col col-lg-8">
				<div class="card">
					<table class="table card-table" v-if="pendingAttempts.length">
						<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Level</th>
								<th scope="col">Flight</th>
								<th scope="col" class="shrink"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="attempt in pendingAttempts">
								<td>{{ attempt.submittedOn.format('yyy/MM/dd') }}</td>
								<td>{{ attempt.level }}</td>
								<td>{{ attempt.flight }}</td>
								<td><a :href="`/admin/user/${attempt.userId}/attempt/${attempt.id}`" class="btn btn-primary">View attempt <span class="fe fe-arrow-right-circle"></span></a></td>
							</tr>
						</tbody>
					</table>
					<div class="card-body" v-else>
						<p class="mb-0">There are no pending approvals.</p>
					</div>
				</div>
				<div class="card">
					<table class="table card-table" v-if="pendingMAPAttempts.length">
						<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Level</th>
								<th scope="col">Flight</th>
								<th scope="col" class="shrink"></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="attempt in pendingAttempts">
								<td>{{ attempt.submittedOn.format('yyy/MM/dd') }}</td>
								<td>{{ attempt.level }}</td>
								<td>{{ attempt.flight }}</td>
								<td><a :href="`/admin/user/${attempt.userId}/map-attempt/${attempt.id}`" class="btn btn-primary">View attempt <span class="fe fe-arrow-right-circle"></span></a></td>
							</tr>
						</tbody>
					</table>
					<div class="card-body" v-else>
						<p class="mb-0">There are no pending MAP approvals.</p>
					</div>
				</div>
			</div>
		</div>
		<hr class="my-4" />
		<div class="row justify-content-center">
			<div class="col">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">HPR Level 1 Progress</h4>
					</div>
					<div class="card-body">
						<div v-if="user.certification.level1.grantedOn && user.certification.level1.grantedOn < getMembershipImportDate()" class="alert alert-light small mb-4"><span class="fe fe-alert-triangle"></span> User achieved Level 1 by the previous certification process, so their progress will show as incomplete.</div>
						<div v-else-if="user.certification.level1.grantedOn" class="alert alert-light small mb-4"><span class="fe fe-alert-triangle"></span> User was granted Level 1 outside of the normal process, so their certification progress may show as incomplete.</div>
						<div class="list-group list-group-flush my-n3">
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Qualification Flight 1
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(user.certification.level1.stage1)}`">
											{{ stageBadgeText(user.certification.level1.stage1) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Qualification Flight 2
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(user.certification.level1.stage2)}`">
											{{ stageBadgeText(user.certification.level1.stage2) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Certification Flight
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(user.certification.level1.stage3)}`">
											{{ stageBadgeText(user.certification.level1.stage3) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">HPR Level 2 Progress</h4>
					</div>
					<div class="card-body">
						<div v-if="user.certification.level2.grantedOn && user.certification.level2.grantedOn < getMembershipImportDate()" class="alert alert-light small mb-4"><span class="fe fe-alert-triangle"></span> User achieved Level 2 by the previous certification process, so their progress will show as incomplete.</div>
						<div v-else-if="user.certification.level2.grantedOn" class="alert alert-light small mb-4"><span class="fe fe-alert-triangle"></span> User was granted Level 2 outside of the normal process, so their certification progress may show as incomplete.</div>
						<div class="list-group list-group-flush my-n3">
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Qualification Flight 1
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(user.certification.level2.stage1)}`">
											{{ stageBadgeText(user.certification.level2.stage1) }}
										</div>
									</div>
								</div>
							</div>
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Certification Flight
									</div>
									<div class="col-auto">
										<div :class="`badge ${stageBadgeClass(user.certification.level2.stage2)}`">
											{{ stageBadgeText(user.certification.level2.stage2) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<hr class="my-4" />
		<div class="row justify-content-center">
			<div class="col col-lg-4">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Membership Card</h4>
					</div>
					<div class="card-body">
						<a :download="`id-card-${user.id}.png`" :href="idCard" target="_blank"><img :src="idCard" style="max-width: 100%" /></a>
						<a :download="`id-card-back-${user.id}.png`" :href="idCardBack" target="_blank"><img :src="idCardBack" style="max-width: 100%" /></a>
					</div>
				</div>
			</div>
			<div class="col col-lg-4">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">HPR Certificates</h4>
					</div>
					<div class="card-body">
						<div v-if="user.certification.level1.status == 'passed'">
							<a :download="`level-1-cert-${user.id}.png`" :href="l1Cert" target="_blank"><img :src="l1Cert" style="max-width: 100%" /></a>
						</div>
						<div v-if="user.certification.level2.status == 'passed'">
							<a :download="`level-2-cert-${user.id}.png`" :href="l2Cert" target="_blank"><img :src="l2Cert" style="max-width: 100%" /></a>
						</div>
						<div v-if="user.certification.level3.status == 'passed'">
							<a :download="`level-3-cert-${user.id}.png`" :href="l3Cert" target="_blank"><img :src="l3Cert" style="max-width: 100%" /></a>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">RSO</h4>
					</div>
					<div class="card-body">
						<div v-if="user.rso.status == 'passed'">
							<a :download="`rso-cert-${user.id}.png`" :href="rsoCert" target="_blank"><img :src="rsoCert" style="max-width: 100%" /></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
</template>