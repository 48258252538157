export default class RocketView
{
	id = null
	ownerId = null
	createdOn = null
	name = null
	buildType = null
	diameter = null
	length = null
	cp = null
	mass = null
	notes = null

	mapFlight = false
	mapTasks = []

	flights = []

	get locked()
	{
		return this.flights.find(f => f.locked) != null;
	}

	getFlight(flightId)
	{
		return this.flights.find(f => f.id == flightId)
	}

	deleteFlight(flightId)
	{
		this.flights.splice(this.flights.findIndex(f => f.id == flightId), 1)
	}

	lockFlight(flightId)
	{
		let flight = this.getFlight(flightId);
		flight.locked = true;
	}

	static Flight = class {
		id = null
		locked = false

		name = null
		flownOn = null
		location = null
		latitude = null
		longitude = null
		motors = null
		delay = null
		mass = null
		sm = null
		notes = null
		logs = []

		outcome = null
		landingLatitude = null
		landingLongitude = null

		mapFlight = false
		mapTasks = []
		mapStatus = null

		flightCardUrl = null
	}
}