<script>
export const pageName = 'map-assessor';
export const pageRoute = '/model-achievement-programme/assessor';
export const title = 'MAP Assessor'
export const requireMembership = true;
export const requireCompleteAccount = true;

import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/lib/functions.js';
import { navigate } from '@trullock/page-manager'
import { showToast } from '../../js/utils/toast.js'
import UserView from '../../../functions/domain/users/userView.js';
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { rsoLevelToLevel } from '../../../functions/domain/certification-utils.js'

export default {
	data() {
		return {
			currentUser: null,
			ready: false
		}
	},

	methods: {
		rsoLevelToLevel,
		async boot(opts) {
			await listenToProjection([UserView, getCurrentUserId()], async user => {
				this.currentUser = user;
				this.ready = true;
			});	
		},
		show() {
			functions.warmUp.userRequestMAPAssessorInterview()
		},
		async submit() {
			
			showLoading();

			let result = await functions.userRequestMAPAssessorInterview();
			
			if(!result.success)
			{
				showToast({ message: 'Error submitting application', style: 'bg-danger'})
				hideLoading();
				return;
			}

			navigate('/');

			showToast({
				message: 'Application submitted',
				style: 'bg-success'
			})

			hideLoading();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">
			<div class="col col-12">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">MAP Assessor</h4>
					</div>
					<div class="card-body">
						<p>To become a UKRA MAP Assessor you must successfully complete a short interview.</p>
						<p>The interview will be conducted by two UKRA officials.</p>
						
						<p>Detailed information about the <a href="https://ukra.org.uk/certification/model-achievement-programme/" target="_blank">MAP Assessor Process <span class="fe fe-external-link"></span></a> can be found on the main UKRA website.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col col-12 col-lg-8">
				<div class="card" v-if="currentUser.map.isAssessor">
					<div class="card-header">
						<h4 class="card-header-title">Complete</h4>
					</div>
					<div class="card-body">
						<p>Congratulations! You've passed your MAP Assessor certification.</p>
					</div>
				</div>
				<div class="card" v-if="!currentUser.map.isAssessor">
					<div class="card-header">
						<h4 class="card-header-title">Application</h4>
					</div>
					<div class="card-body">
						<form ref="form" @submit.prevent="submit">
							<p class="alert alert-light small">Please note: If you have recently applied and not heard back, please wait a short while. UKRA is staffed by volunteers on a part time basis and will respond to your enquiry as soon as possible.</p>
							<button type="submit" class="btn btn-lg btn-primary btn-block">Apply <span class="fe fe-check-circle"></span></button>
						</form>
					</div>
				</div>
			</div>
			<div class="col col-12 col-lg-4">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Progress</h4>
					</div>
					<div class="card-body">
						<div class="text-secondary small mb-4">Complete one interview for MAP Assessor.</div>
						<div class="list-group list-group-flush my-n3">
							<div class="list-group-item">
								<div class="row">
									<div class="col small">
										Interview
									</div>
									<div class="col-auto">
										<div :class="`badge ${currentUser.map.isAssessor ? 'badge-success' : 'badge-light'}`">
											{{ currentUser.map.isAssessor ? 'Passed' : 'Not passed' }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
