<script>
export const pageName = 'certification-buy-certificate';
export const pageRoute = '/certification/buy-a-certificate/{type}';
export const title = 'Buy a certificate of achievement'
export const requireMembership = true;
export const requireCompleteAccount = true;

import { getCurrentUserId } from '../../js/auth.js';
import { functions } from '../../js/lib/functions.js';
import { listenToProjection } from '../../js/lib/client-read-model.js';
import { PageShowError } from '@trullock/page-manager'
import { loadStripe } from '../../js/utils/stripe.js'
import UserView from '../../../functions/domain/users/userView.js';
import { attemptTypeToLevel } from '../../../functions/domain/certification-utils.js';

export default {
	data() {
		return {
			currentUser: null,
			type: null,
			ready: false
		}
	},

	methods: {
		async boot(opts) {
			await listenToProjection([UserView, getCurrentUserId()], user => this.currentUser = user);
			this.stripe = await loadStripe();
			this.type = attemptTypeToLevel(this.type);
			this.ready = true;
		},

		async show(opts) {

			let type = opts.type.toUpperCase();

			switch(type)
			{
				case 'LEVEL1':
					if(this.currentUser.certification.level1.status != 'passed')
						throw new PageShowError('/', 'You must be L1 to buy a Level 1 certificate', {}, 'replace');
					break;

				case 'LEVEL2':
					if(this.currentUser.certification.level2.status != 'passed')
						throw new PageShowError('/', 'You must be L2 to buy a Level 2 certificate', {}, 'replace');
					break;

				case 'LEVEL3':
					if(this.currentUser.certification.level3.status != 'passed')
						throw new PageShowError('/', 'You must be L3 to buy a Level 3 certificate', {}, 'replace');
					break;

				case 'RSO':
					if(this.currentUser.rso.status != 'passed')
						throw new PageShowError('/', 'You must be an RSO to buy an RSO certificate', {}, 'replace');
					break;

				case 'MAPA':
					if(this.currentUser.map.isAssessor)
						throw new PageShowError('/', 'You must be a MAP Assessor to buy a MAP Assessor certificate', {}, 'replace');
					break;
			}

			let response = await functions.stripeCreateSessionCertificate({ type });
			this.checkout = await this.stripe.initEmbeddedCheckout({
				fetchClientSecret: () => response.client_secret
			});

			this.checkout.mount(this.$refs.stripeMount);
		},

		hide()
		{
			this.checkout?.destroy();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div v-if="ready" class="container py-5">
		<div class="row">
			<div class="col col-12">
				<div class="card">
					<div class="card-header">
						<h4 class="card-header-title">Buy a {{this.type}} certificate</h4>
					</div>
					<div class="card-body">
						<div ref="stripeMount"></div>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
