import { addBusinessDays, addDays, addMonths, addSeconds, addYears, format } from 'date-fns'
import { enGB } from 'date-fns/locale/index.js';


export default class DateTime
{
	_date = 0

	constructor()
	{
		if(arguments.length == 1)
		{
			let value = arguments[0]
			if(value instanceof DateTime)
				this._date = value._date;
			else if(value instanceof Date)
				this._date = value;
			else if(typeof value == 'number')
				this._date = new Date(value);
			else if(typeof value == 'string')
				this._date = new Date(Date.parse(value));
			else if(value == null)
				throw new Error(`Error constructing DateTime from null`);
			else
				throw new Error(`Error constructing DateTime from: ${[...arguments].join(', ')}`);
		}
		else if(arguments.length > 1)
		{
			// BUG: this should parse as UTC
			this._date = addMonths(new Date(Date.UTC.call(null, ...arguments)), -1)
		}
	}

	valueOf()
	{
		return this._date.getTime()
	}

	static get now()
	{
		return new DateTime(new Date());
	}

	static get today()
	{
		return DateTime.now.dateOnly();
	}

	get year()
	{
		return this._date.getFullYear()
	}

	get month()
	{
		return this._date.getMonth() + 1;
	}

	get day()
	{
		return this._date.getDate();
	}

	get dayOfYear()
	{
		return (Date.UTC(this._date.getFullYear(), this._date.getMonth(), this._date.getDate()) - Date.UTC(this._date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
	}

	addBizDays(days)
	{
		return new DateTime(addBusinessDays(this._date, days));
	}

	addSeconds(seconds)
	{
		return new DateTime(addSeconds(this._date, seconds));
	}

	addDays(days)
	{
		return new DateTime(addDays(this._date, days));
	}

	addYears(years)
	{
		return new DateTime(addYears(this._date, years));
	}

	dateOnly()
	{
		return new DateTime(this._date.getFullYear(), this._date.getMonth() + 1, this._date.getDate(), 0, 0, 0);
	}

	toString()
	{
		return this._date.toLocaleString();
	}

	toISOString()
	{
		return this._date.toISOString();
	}
	
	// https://date-fns.org/v2.29.3/docs/format
	format(fmt)
	{
		return format(this._date, fmt , { locale: enGB });
	}

	equals(other)
	{
		return other?.valueOf() == this.valueOf();
	}
}