<script>
export const pageName = 'rockets-flight-edit';
export const pageRoute = '/rockets/{rocketId}/flights/{flightId}';
export const title = 'Edit Flight'
export const requireMembership = true;

import Modal from '../../js/utils/modal.js';
import Cropper from 'cropperjs'
import { hideLoading, showLoading } from '../../js/utils/loading.js';
import { navigate } from '@trullock/page-manager'
import { functions } from '../../js/lib/functions.js';
import { showToast } from '../../js/utils/toast.js'
import DateTime from '../../../functions/lib/dateTime.js';
import TomSelect from 'tom-select'
import { nextTick } from 'vue';
import { getUserMap } from '../../js/utils/map.js';
import { getCurrentUserId } from '../../js/auth.js';
import { getRocketView, getUserView } from '../../js/readModel.js';

export default {
	data() {
		return { 
			user: null,
			date: null,
			dateMax: DateTime.now.format('yyyy-MM-dd'),
			time: null,
			name: null,
			location: null,
			launchLatitude: null,
			launchLongitude: null,
			landingLatitude: null,
			landingLongitude: null,
			motors: null,
			delay: null,
			mass: null,
			sm: null,
			notes: null,
			outcome: '',
			flightLogs: [],
			flightLogError: null,
			existingLogs: [],
			outcome: null,
			mapFlight: false,
			mapTasks: [],
			flightCard: null,
			isDirty: false,
			ready: false
		}
	},

	methods: {
		beforeHide() {
			return this.isDirty ? 'Are you sure you want to abandon your changes?' : null
		},
		async show(opts) {
			functions.warmUp.rocketFlightUpdate();

			this.user = await getUserView(getCurrentUserId());
			this.rocket = await getRocketView(getCurrentUserId(), opts.rocketId)
			this.flight = this.rocket.getFlight(opts.flightId);

			this.date = this.flight.flownOn.format('yyyy-MM-dd');
			this.time = this.flight.flownOn.format('HH:mm');

			this.name = this.flight.name;
			this.location = this.flight.location;
			this.launchLatitude = this.flight.latitude;
			this.launchLongitude = this.flight.longitude;
			this.landingLatitude = this.flight.landingLatitude;
			this.landingLongitude = this.flight.landingLongitude;
			this.motors = this.flight.motors;
			this.delay = this.flight.delay;
			this.mass = this.flight.mass;
			this.sm = this.flight.sm;
			this.notes = this.flight.notes;
			this.outcome = this.flight.outcome;

			this.flightLogs = [];
			this.existingLogs = this.flight.logs.map(l => ({
				id: l.id,
				name: l.name,
				size: l.size,
				remove: false
			}))
			
			this.mapTasks = this.flight.mapTasks;
			let map = await getUserMap(this.user);
			let allTasks = map.levels.flatMap(l => l.subjects.flatMap((s, si) => s.tasks.filter(t => t.mode != 'future' && !t.complete).map((t, ti) => ({
				sort: si * 100 + ti,
				value: t.id,
				text: t.name,
				icon: t.icon,
				bg: t.bg,
				subjectId: s.id
			}))))

			allTasks.sort((a, b) => a.sort - b.sort)

			this.ready = true;
			await nextTick();
			
			if(!this.$mapTasks)
			{
				this.$mapTasks = new TomSelect(this.$refs.mapTasks, {
					options: allTasks,
					render: {
						option: function (data, escape) {
							return `<div><div class="ts-map-icon" style="background: url(${data.bg})"><img src="${data.icon}"></div>${data.text}</div>`;
						},
						item: function (item, escape) {
							return `<div><div class="ts-map-icon" style="background: url(${item.bg})"><img src="${item.icon}"></div>${item.text}</div>`;
						}
					},
					onItemAdd: function(){
						this.setTextboxValue('');
						this.clearOptions();

						let subjects = this.items.map(i => i.split('.')[0]);

						let newOptions = allTasks.filter(t => subjects.indexOf(t.subjectId) == -1 || t.subjectId == 'bonus')
						this.addOptions(newOptions);

						this.refreshOptions();
					},
					onItemRemove: function(){
						this.setTextboxValue('');
						this.clearOptions();

						let subjects = this.items.map(i => i.split('.')[0]);

						let newOptions = allTasks.filter(t => subjects.indexOf(t.subjectId) == -1 || t.subjectId == 'bonus')
						this.addOptions(newOptions);

						this.refreshOptions();
					},
					maxItems: null,
					create: false,
					plugins: {
						remove_button:{
							title:'Remove this item',
						}
					},
				});
			}

			this.isDirty = false;
		},
		geolocateLaunch() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(async position => {
					this.launchLatitude = position.coords.latitude;
					this.launchLongitude = position.coords.longitude;
				});
			} else {
				showToast({
					message: 'Error geolocating you',
					style: 'bg-danger'
				})
			}
		},
		geolocateLanding() {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(async position => {
					this.landingLatitude = position.coords.latitude;
					this.landingLongitude = position.coords.longitude;
				});
			} else {
				showToast({
					message: 'Error geolocating you',
					style: 'bg-danger'
				})
			}
		},
		changed() {
			this.isDirty = true;
			this.mapFlight ? this.$mapTasks.enable() : this.$mapTasks.disable()
			this.$refs.fupFlightCard.disabled = !this.mapFlight
		},
		flightLogAdded(e) {
			var files = [...e.target.files];
			this.handleFiles(files);
		},
		handleFiles(files) {
			
			this.flightLogError = '';

			let filesProcessed = 0;

			var done = async (log, file) => {
				filesProcessed++;
				if(filesProcessed == files.length)
					hideLoading();

				this.$refs.fupFlightLogs.value = null;

				let result = await functions.rocketFlightLogValidate({ log })
				if(!result.valid)
				{
					this.flightLogError += `Unsupported flight log '${file.name}'. If this is a valid flight log file please email it to membership@ukra.org.uk so we can investigate supporting it.`;
					return;
				}

				if(file.size > 5 * 1024 * 1024)
				{	
					this.flightLogError += `'${file.name}' is too large, maximum log size is 5MB.`;
					return;
				}

				this.flightLogs.push({ 
					log, 
					name: file.name,
					size: file.size
				});

				this.$refs.fupFlightLogs.value = null;
			};

			let error = (result, e) => {
				filesProcessed++;
				if(filesProcessed == files.length)
					hideLoading();

				console.error(e);
			}

			showLoading();
			
			files.forEach(f => {
				let reader = new FileReader();
				reader.onload = () => done(reader.result, f);
				reader.onerror = e => error(reader.result, e);
				reader.readAsText(f);
			});
		},
		removeLog(log) {
			this.flightLogs.splice(this.flightLogs.findIndex(l => l == log), 1);
		},
		removeExistingLog(log) {
			log.remove = true;
		},
		dropped(e) {
			e.preventDefault();
			let files = [...e.dataTransfer.files];
			
			let dudFiles = files.filter(f => f.type != "text/csv");

			dudFiles.forEach(file => {
				this.$refs.fupFlightLogs.setCustomValidity(`Unsupported file type '${file.name}'.`);
				this.$refs.fupFlightLogs.reportValidity();
			})
			
			files = files.filter(f => f.type == "text/csv");

			if(files.length)
				this.handleFiles(files);
		},
		dragover(e) {
			e.preventDefault();
		},
		uploadClick(e) {
			this.$refs.fupFlightLogs.click();
		},
		async submit () {
			showLoading();

			this.flightLogError = null;

			let flownOn = `${this.date} ${this.time}`;
			flownOn = new DateTime(flownOn)

			let tasks = []
			
			tasks.push(functions.rocketFlightUpdate({ 
				rocketId: this.rocket.id,
				flightId: this.flight.id,
				flownOn,
				name: this.name,
				location: this.location,
				launchLatitude: parseFloat(this.launchLatitude || '0') || null,
				launchLongitude: parseFloat(this.launchLongitude || '0') || null,
				motors: this.motors,
				delay: parseFloat(this.delay || '0') || null,
				mass: parseInt(this.mass || '0', 10) || null,
				sm: parseInt(this.sm || '0', 10) || null,
				landingLatitude: parseFloat(this.landingLatitude || '0') || null,
				landingLongitude: parseFloat(this.landingLongitude || '0') || null,
				outcome: this.outcome,
				mapFlight: this.mapFlight,
				mapTasks: this.mapTasks,
				flightCard: this.flightCard,
				notes: this.notes
			}));


			this.flightLogs.forEach(log => {
				tasks.push(functions.rocketFlightLogAttach({
					rocketId: this.rocket.id,
					flightId: this.flight.id,
					log: log.log,
					name: log.name	
				}))
			})

			this.existingLogs.filter(l => l.remove).forEach(l => {
				tasks.push(functions.rocketFlightLogRemove({
					rocketId: this.rocket.id,
					flightId: this.flight.id,
					logId: l.id
				}))
			})

			let results = await Promise.allSettled(tasks);
			
			if(results.filter(r => r.status == 'rejected').length != 0)
			{
				// TODO better error handling here
				showToast({ message: 'Error editing flight', style: 'bg-danger'})
				hideLoading();
				return;
			}

			this.isDirty = false;

			navigate(`/rockets/${this.rocket.id}`)

			showToast({
				message: 'Flight updated',
				style: 'bg-success'
			})

			hideLoading();
		}
	},
	props: [ 'options' ]
}

</script>
<template>
	<div class="container py-5" v-if="ready">
		<div class="row justify-content-center">
			<div class="col-12 col-md-8 col-xl-6 my-5">
				<h1 class="display-4 text-center mb-3">Edit Flight</h1>
				<p class="text-muted text-center mb-5">Update a previous flight.</p>
					
				<form @submit.prevent="submit" @change="changed">
					<h2>Flight</h2>
					<div class="form-group">
						<label class="form-label">Description</label>
						<input type="text" class="form-control" v-model="name" name="flight-desc" placeholder="e.g. First flight" autofocus>
						<span class="invalid-feedback"></span>
						<p class="form-text text-muted small mb-0">Optional. A unique description for this flight.</p>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">Date</label>
								<input type="date" class="form-control pe-3" v-model="date" :max="dateMax" :required="mapFlight">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">Time</label>
								<input type="time" class="form-control pe-3" v-model="time" :required="mapFlight">
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					<hr class="mt-2 mb-4" />
					<h2>Launch site</h2>
					<div class="form-group">
						<label class="form-label">Name</label>
						<input type="text" class="form-control" v-model="location" placeholder="e.g. Cape Canaveral">
						<span class="invalid-feedback"></span>
					</div>
					<div class="row mb-2">
						<div class="col">
							<div class="form-group mb-0">
								<label class="form-label">Latitude</label>
								<input type="number" class="form-control" v-model="launchLatitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col">
							<div class="form-group mb-0">
								<label class="form-label">Longitude</label>
								<input type="number" class="form-control" v-model="launchLongitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-auto d-flex align-items-end pt-4 ps-0">
							<button @click.prevent="geolocateLaunch" class="btn btn-outline-secondary"><span class="fe fe-map-pin"></span></button>
						</div>
					</div>
					
					<div class="form-text text-muted small mb-4">Use the Geolocate button in the field for exact coordinates.</div>

					<hr class="mt-2 mb-4"/>
					<h2>Configuration</h2>
					<div class="row">
						<div class="col-9">
							<div class="form-group">
								<label class="form-label">
									Motor(s)
								</label>
								<input type="text" class="form-control" v-model="motors" placeholder="e.g. G80SK" :required="mapFlight">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-3">
							<div class="form-group">
								<label class="form-label">
									Delay
								</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="240" step="1" autocomplete="off" v-model="delay" class="form-control" placeholder="e.g. 7" :required="mapFlight" />
									<div class="input-group-append">
										<div class="input-group-text">
											s
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">
									Lift-off mass
								</label>
								<div class="input-group has-validation">
									<input type="number" min="1" max="99999" step="1" autocomplete="off" v-model="mass" class="form-control" placeholder="e.g. 1100" />
									<div class="input-group-append">
										<div class="input-group-text">
											g
										</div>
									</div>
									<span class="invalid-feedback"></span>
								</div>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label class="form-label">
									Stability margin
								</label>
								<input type="number" class="form-control" v-model="sm" min="0" max="100" step="0.1" placeholder="e.g. 1.1">
								<span class="invalid-feedback"></span>
							</div>
						</div>
					</div>
					
					<div class="form-group">
						<label class="form-label mb-1">Notes</label>
						<textarea class="form-control" v-model="notes" rows="4"></textarea>
					</div>
					<hr class="my-5" />
					<h2 id="outcome">Outcome</h2>

					<div class="form-group">
						<label class="form-label">Flight result</label>
						<select :required="mapFlight" v-model="outcome" class="form-control">
							<option value="">Not flown yet</option>
							<option value="positive">Positive</option>
							<option value="neutral">Neural</option>
							<option value="negative">Negative</option>
						</select>
						<span class="invalid-feedback"></span>
						<span class="form-text text-muted small mb-4">Was the flight a success?</span>
					</div>
					<div class="row mb-2">
						<div class="col">
							<div class="form-group mb-0">
								<label class="form-label">Latitude</label>
								<input type="number" class="form-control" v-model="landingLatitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col">
							<div class="form-group mb-0">
								<label class="form-label">Longitude</label>
								<input type="number" class="form-control" v-model="landingLongitude" step="0.0000001">
								<span class="invalid-feedback"></span>
							</div>
						</div>
						<div class="col-auto d-flex align-items-end pt-4 ps-0">
							<button @click.prevent="geolocateLanding" class="btn btn-outline-secondary"><span class="fe fe-map-pin"></span></button>
						</div>
					</div>
					<div class="form-text text-muted small mb-4">Use the Geolocate button in the field for exact coordinates.</div>

					<div class="form-group">
						<label>Flight logs</label>
						<div class="form-control form-control-dropzone">
							<div ref="dzLogs" class="dropzone dropzone-multiple dz-clickable" @click="uploadClick" @drop="dropped" @dragover="dragover">
								<input type="file" @change="flightLogAdded" ref="fupFlightLogs" multiple accept="text/csv" />
								<div class="dz-default dz-message">
									<button class="dz-button" type="button"><span class="fe fe-plus"></span> Add flight log CSVs</button>
								</div>
							</div>
							<div v-if="flightLogError" class="text-danger small mt-2">{{ flightLogError }}</div>
							<div class="list-group list-group-flush pt-4 my-n3">
								<div v-for="log of flightLogs" class="list-group-item">
									<div class="row align-items-center">
										<div class="col ms-n2">
											<h4 class="fw-normal mb-1">
												{{ log.name }}
											</h4>
										</div>
										<div class="col-auto">
											<button class="text-danger" type="button" @click="removeLog(log)"><span class="fe fe-x"></span></button>
										</div>
									</div>
								</div>
								<template v-for="log of existingLogs">
									<div v-if="!log.remove" class="list-group-item">
										<div class="row align-items-center">
											<div class="col ms-n2">
												<h4 class="fw-normal mb-1">
													{{ log.name }}
												</h4>
											</div>
											<div class="col-auto">
												<button class="text-danger" type="button" @click="removeExistingLog(log)"><span class="fe fe-x"></span></button>
											</div>
										</div>
									</div>
								</template>
							</div>
						</div>
						<p class="form-text text-muted small mb-0">This feature is still under development, if your flight log isnt supported please <a href="mailto:membership@ukra.org.uk">send it to us</a>.</p>
					</div>
					<hr class="my-5" />
					<h2>Model Achievement Programme</h2>
					<div v-if="user.map.attemptPending" class="alert alert-light small mb-4"><span class="fe fe-info"></span> You currently have a pending MAP attempt, please wait for UKRA to approve it before submitting another.</div>
					<div class="form-group">
						<div class="custom-control custom-switch">
							<input type="checkbox" class="custom-control-input" :value="true" v-model="mapFlight" :disabled="user.map.attemptPending">
							<label class="custom-control-label">This is a MAP flight</label>
						</div>
					</div>
					<div class="form-group">
						<label class="form-label">
							MAP objectives
						</label>
						<select name="map" v-model="mapTasks" ref="mapTasks" class="form-control" :disabled="!mapFlight" required></select>
						<span class="invalid-feedback mt-2 d-block"></span>
						<p class="form-text text-muted small mb-0">This must match the flight card.</p>
					</div>
					<div class="field form-group">
						<label>
							Upload a picture of the signed flight card
							<div class="dz-default dz-message py-3" v-if="flightCard">
								<div class="avatar avatar-xxl">
									<img ref="imgAvatar" class="avatar-img" :src="flightCard" alt="Flight Card">
								</div>
							</div>
						</label>
						<div class="custom-file">
							<input type="file" @change="flightCardAdded" :disabled="!mapFlight" ref="fupFlightCard" class="custom-file-input" name="flight-card" accept="image/*;capture=camera">
							<label ref="lblFlightCard" class="custom-file-label text-muted">Choose image&hellip;</label>
							<span class="invalid-feedback"></span>
						</div>
						<span class="form-text text-muted small mb-0">Must be a jpeg or png.</span>
					</div>
					<button class="btn w-100 btn-primary" v-if="!mapFlight">Save changes <span class="fe fe-check-circle"></span></button>
					<button class="btn w-100 btn-success" v-if="mapFlight">Submit flight <span class="fe fe-check-circle"></span></button>
					<span class="form-text text-muted text-center small mb-0" v-if="mapFlight">This flight will not be editable after submitting.</span>
				</form>
			</div>
		</div>
    </div>
</template>
